.button1{
  font-family: "Poppins";
  font-size: 15px;
  color: #ffffff;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #09868F;
  border-color: #09868F;
  border-width: 9px;
  border-style: solid;
  border-radius: 30px;

}


.color-menu{
  color: #09868F;
}

.cosecha{
  position: relative;
    top:60px;
    width: 1440px;
    height: 552px;
    right: 345px;
}

.custitle{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 76px;
}

.color{

    background: #E8FCFF;

}

.tit1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: initial;
}

.tit2{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: initial;

/* Primary */

    color: #09868F;
}


/*************************
*********Leader***********
*************************/

.canasta{
    position: relative;
    top:0px;
    width: 709px ;
    height: 677px;
    right: 400px;
    margin-bottom: 100px;
}

.tit3{
    font-size: 48px;
    line-height: 72px;

}
.color-a{
  color: #FC6A57;
  font-weight: 500;
}
.tit4{
  width: 600px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    text-align: initial;
}


/*************************
*******Categories*********
*************************/

.sect{
  padding-top: 0px;
}

.containervec {  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 0.5fr 1fr 1fr 0.5fr;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
}

.vec1 {
  justify-self: stretch;
  grid-area: 1 / 1 / 2 / 4;
}

.apple {
  align-self: stretch;
  grid-area: 2 / 1 / 3 / 2;
}

.comp1 {
  align-self: stretch;
  grid-area: 2 / 1 / 3 / 4;
}

.uno {
  justify-self: center;
  align-self: stretch;
  grid-area: 2 / 1 / 4 / 2;
}

.dos {
  justify-self: center;
  grid-area: 2 / 2 / 4 / 3;
}

.tres {
  justify-self: center;
  grid-area: 2 / 3 / 4 / 4;
}

.cuatro {
  justify-self: center;
  grid-area: 4 / 1 / 5 / 2;
}

.cinco {
  justify-self: center;
  grid-area: 4 / 2 / 5 / 3;
}

.seis {
  justify-self: center;
  grid-area: 4 / 3 / 5 / 4;
}

.vec2 {
  justify-self: stretch;
  grid-area: 8 / 1 / 6 / 4;
}

.vec1 img{
  position: relative;
   width: 1920px;
   height: auto;
   margin-left: -400px;
   top:-100%
 }

.vec2 img{
  position: relative;
   width: 1920px;
   height: auto;
   margin-left: -400px;
   top:-50%
 }

.comp{
  position: relative;
  left: 0%;
  right: 0%;
  top: -200px;
  bottom: 0%;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 63px;
  color: #09868F;
}

.apple{
    position: absolute;
    width: 473px;
    height: 513px;
    left: -3%;
    top: -10%;
}



/*************************
*********Carousel*********
*************************/


.corrusel{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.fleizq,.fleder{
  width: 25%;
  background-color: transparent;
  padding: 10px;
  border-color: transparent;
  cursor: pointer;
}


.fleizq:focus{
  outline: none;
  border: none;
}
.fleder:focus{
  outline: none;
  border: none;
}
.titcarrusel{
    position: relative;
  left: 0%;
  right: 0%;
  margin-top:-335px;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;

  color: #09868F;
  }

  .items{
    height: 15rem;

    padding: 3rem;

  }
  .items img{
    height: 100%;
    width: 100%;
    pointer-events: none;
}


/*************************
*******Testimonial********
*************************/

.testimonial{
  font-family: 'Poppins';
  font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 63px;

}

.test{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 27px;
text-align: center;

color: #000000;
}

.pera{
  position: absolute;
width: 327px;
height: 446px;
left: 1578px;
top: 400px;

}

/*************************
**********Coche***********
*************************/

.coche{
    position: relative;
    width: 100%;
    margin-left: 34px;
}

.coche1{
  position: relative;
  width: 715px;
  height: 326px;
  margin-left: 40px;
}

/*************************
********Formulario********
*************************/


  .titleform{
    width: 100%;
    position: relative;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 63px;
    display: flex;
    text-align:center;

    /* Primary1 */

    color: #09868F;
  }
  .subform{
    position: relative;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;

    color: #25282B;
}


  .texts1{
    position: relative;
  width: 100%;
  height: 27px;

  top:0px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align:initial;
  /* identical to box height */


  color: #6e6e6e;
  }

  .cityselector{
  position: relative;

  height: 40px;
  left: 0px;
  top: 0px;

  background: #F9F9F9;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border: 0px solid rgb(48, 48, 48);
  border-radius: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  color: #000000;
  }
  .textsucursal{
    position: relative;
  width: 100%;
  height: 27px;

  top:20px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align:initial;
  /* identical to box height */


  color: #6e6e6e;
  }
  .sucursalselector{
    position: relative;

    height: 40px;
    left: 0px;
    top: 20px;

    background: #F9F9F9;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    border: 0px solid rgb(48, 48, 48);
    border-radius: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;

    color: #000000;
    }
  .nameLabel{
    position: relative;
    width: 100%;
    height: 27px;
    left: 0px;
    top: 50px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: initial;
    color: #6e6e6e;
    }

    .nameInput{
      position: relative;
    width: 100%;
    height: 40px;
    left: 0px;
    top: 45px;

    background: #F9F9F9;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    border: 0px solid rgb(48, 48, 48);
    border-radius: 16px;

    }

    .Numberlabel{
        position: relative;
        width: 100%;
        height: 27px;
        left: 0px;
        top: 80px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: initial;
        color: #6e6e6e;
        }

        .NumberInput{
          position: relative;
        width: 100%;
        height: 40px;
        left: 0px;
        top: 80px;

        background: #F9F9F9;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
        border: 0px solid rgb(48, 48, 48);
        border-radius: 16px;
        }

        .Messagelabel{
            position: relative;
            width: 100%;
            height: 27px;
            left: 0px;
            top: 110px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            text-align: initial;
            color: #6e6e6e;
          }

          .MessageInput{
            position: relative;
          width: 100%;
          height: 150px;
          left: 0px;
          top:110px;
          background: #F9F9F9;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
          border: 0px solid rgb(48, 48, 48);
          border-radius: 16px;
          }




/*************************
**********About***********
*************************/
.tit5{
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 36px;
    text-align: initial;
}

.custitle1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 72px;
}

.titp{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: initial;

/* Primary */

    color: #26a515b9;
}

.elote{
  position: relative;
width: 649px;
height: 760.46px;
left: 0px;
top: 22px;
}


/*************************
***********App************
*************************/

.titleapp{
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 63px;

text-align: center;

/* Primary1 */

color: #09868F;
}

.apps{
  width: 428px;
height: 494px;
}

/*************************
*********Solutions********
*************************/

.solutions{
  text-align: center;
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 63px;

color: #09868F;
}

/**exhibicion*/
.exi{
  height: 60vh;

}
.exi-two{
  height: 50vh;

}

.exi-three{
  height: 70vh;
}

.exh{
  position: relative;
  top:0px;
  width: 814px ;
  height: 897px;
  right: 200px;
}

.titl{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 63px;

color: #FD8B0A;

}




/*Industria*/

.ind{
  position: relative;
  top:0px;
  width: 814px ;
  height: 907px;
  left: 60px;
}

.titlep{
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 36px;

color: #25282B;
}
.titI{

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 63px;

color: #479A66;

}

/*Refri*/
.ref{
  position: relative;
  top:0px;
  width: 814px ;
  height: 997px;
  right: 200px;
}

.titf{
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 63px;

color: #FD8B0A;

}


.envio{
  position: relative;
  top:0px;
  width: 814px ;
  height: 997px;
  left: 60px;
}


/*Fonds*/
.gallery-imgs{
  position: relative;
  border-radius: 50px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
}


.recaptcha {
  position: relative;
  /* width: 100%; */
  top: 110px;
  /* height: 131px; */

}

.recaptcha-text{
  font-size: 12px;
    display: flex;
    justify-content: space-evenly;
    align-content: stretch;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}



/*****************************************
*********Terminos y condiciones***********
*****************************************/

.container-terms,.container-privacity{
  position: relative;
width: 1440px;
height: 570px;
right: 5%;
top: 64px;
}

.title-terms{
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 64px;
line-height: 96px;
color: #FC6A57;

}



.color1{
  padding: 120px 0;
  background-color:#E8FCFF;
}

.subtitle-terms,.subtitle-conditions{
  width: 521px;
height: 144px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 36px;

color: #25282B;
}



.candado{
  position: relative;
width: 520px;
height: 235px;
left: 150px;
top: 0px;
}

.carretilla{
  position: relative;
width: 423px;

left: 150px;
top: 0px;
}

.title-terms1{
  position: relative;
right: 50px;
  width: 588px;
height: 50px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 48px;

color: #09868F;
}

.title-terms2{
  position: relative;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  right: 50px;
}

.subtitle-terms1{
  text-align: justify;
  position: relative;
right: 50px;
width: 590px;
height: 779px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 36px;
}

/*****************************************
***********Aviso de privacidad************
*****************************************/
.title-privacity{
  width: 670px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 64px;
line-height: 96px;

color: #FC6A57;
}

.subtitle-a{
  color: #09868F;
}

.icons{
  margin-top: 30px;
  flex-direction: row-reverse;
}

.terms2{
  margin-top: 155px;
}

.subtitle-terms2{
  text-align: justify;
  position: relative;
right: 50px;
width: 590px;
height: 779px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 36px;
}


/*****************************************
***********Quiénes somos************
*****************************************/
.title-team{
  width: 370px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 64px;
line-height: 96px;

color: #FC6A57;
}

.team-img{
  
width: 400px;

left: 150px;
top: 0px;
}

.card{
  padding: 0px;
  width: 18rem;
}



.card-img-top{
  margin-top: 50px;
  width: 120px;
height: 120px;
border-radius: 120px;
}

.card-edit{
  box-shadow: 5px 12px 9px rgba(85, 85, 85, 0.08);
  align-items: center;
  border-radius: 16px;
}

.card-box{
  border: 0px transparent;
}

.card-title {
  color: #000000;
}
.card-text{
  font-weight: 500;

}

.card-title,.card-text{
  text-align: center;
}



/*************************
*********Footer***********
*************************/

.decoration{

    text-decoration-line: underline;

    color: #FFFFFF;
}

.carriot{
   position: relative;
  width: 1050px;
  height: 160px;

  margin-top: -90px;
}

.pasto{
    position: relative;
    width: 1920px;
    height: 33.15px;
    right: 400px;
    margin-top: -80px;
    background: #81C321;
}


/**********************************
**************MEDIA****************
***********************************/

/*******IPAD*******/

@media screen and (min-width: 1366px) and (max-width: 1367px){
  
  .cosecha {

    top: 94px;
    width: 1086px;
    height: 552px;
    right: 303px;
}

.canasta {
  top: 27px;
  width: 793px;
  height: 780px;
  right: 9vw;
  margin-bottom: 100px;
}

.pera {
  width: 244px;
  height: 377px;
  left: 1122px;
  top: 400px;
}

.titleform, .subform {
  width: 565px;
  text-align: center;
  right: 90px;
}
.apple {
  position: absolute;
  width: 473px;
  height: 471px;
  left: -4%;
  top: -12%;
}

.form1{
  width: 660px;
  margin-left: -110px;

}

.titf{
  margin-top: 350px;
}
.titE{
  margin-top: 500px;
}

.titI{
  margin-top: 300px;
}

  .exh {
    top: -50px;
    width: 740px;
    height: 897px;
    right: 65px;
}

.ind {
  top: 30px;
  width: 725px;
  height: 907px;
  left: -28px;
}

.ref {
  top: 90px;
  width: 779px;
  height: 997px;
  right: 64px;
}

.envio {
  top: 150px;
  width: 709px;
  height: 923px;
  left: -20px;
}
.gallery-imgs{
  width:300px;
  left:20px;
}

.column{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.title-terms1{
  right: 19%;
}

.subtitle-terms1{

  width: 80vw;
right: 19%;


}

.subtitle-terms2{
 height: 0vh;
  width: 80vw;
  top: -290px;
  right: 60.5%;

  }

  .candado{
  width: 620px;
  height: 335px;
  left: 30px;
  top: 140px;
  }

  .carretilla{
  width: 483px;
  left: 80px;
  top: 100px;
  }

  .title-terms2{
    
    right: 200px;
  }

}

/*@media screen and (max-width: 1243px) and (max-width: 1243px){

  .section-title-wrap p {
    max-width: 551px;
    margin-bottom: 20px;
}

  .navbar-nav{
    left: 260px;
  }
  
  .cosecha {

    top: 94px;
    width: 1086px;
    height: 552px;
    right: 332px;
}

.canasta {
  top: 27px;
  width: 720px;
  height: 730px;
  right: 9vw;
  margin-bottom: 100px;
}

.pera {
  width: 244px;
  height: 377px;
  left: 1000px;
  top: 400px;
}

.titleform, .subform {
  width: 565px;
  text-align: center;
  right: 90px;
}
.apple {
  position: absolute;
  width: 400px;
  height: 400px;
  left: -4%;
  top: -12%;
}

.form1{
  width: 660px;
  margin-left: -110px;

}

.elote{
  width: 570px;
}

.titf{
  margin-top: 350px;
}
.titE{
  margin-top: 500px;
}

.titI{
  margin-top: 300px;
}

  .exh {
    top: -50px;
    width: 671px;
    height: 724px;
    right: 65px;
}

.ind {
  top: 30px;
  width: 663px;
  height: 754px;
  left: -28px;
}

.ref {
  top: 90px;
  width: 707px;
    height: 860px;
  right: 64px;
}

.envio {
  top: 150px;
  width: 648px;
  height: 845px;
  left: -20px;
}
.gallery-imgs{
  width:300px;
  left:20px;
}

.column{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.title-terms1{
  right: 19%;
}

.subtitle-terms1{

  width: 80vw;
right: 19%;


}

.subtitle-terms2{
 height: 0vh;
  width: 80vw;
  top: -249px;
    right: 57%;

  }

  .candado{
  width: 620px;
  height: 335px;
  left: 30px;
  top: 140px;
  }

  .carretilla{
  width: 483px;
  left: 80px;
  top: 100px;
  }

  .title-terms2{
    
    right: 200px;
  }

  .subtitle-conditions_2{
    top: -218px;
  }

}*/

@media screen and (min-width: 1024px) and (max-width: 1025px){
 
  
  .navbar-nav {
    left: 150px;
  }

  .custitle{
    font-size: 64px;
  line-height: 96px;
  }

  .tit2{
    padding-right: 224px;
    font-weight: 600;
  }

  .tit3{
    font-size: 48px;
    margin-left: -85px;
    margin-top: -110px;
  }
  .tit4{
    width: 470px;
    margin-left: -85px;
    margin-top: 75px;
    font-size: 24px;
  line-height: 36px;

  }

  .cosecha{
    width: 1098px;
    height: 572px;
    top: 103px;
    right: 380px;
  }

  .canasta{
    top: 35px;
    width: 601px;
    height: 618px;
    right: 5vw;
  }


  .containervec {  display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.2fr 0.8fr 1.5fr 1.4fr 1.4fr 1fr;
    grid-auto-columns: 1fr;
    gap: 5px 30px;
    grid-auto-flow: row;
  }

  .comp1 {
    justify-self: center;
    align-self: center;
    grid-area: 1 / 1 / 3 / 3;
  }

  .uno {
    justify-self: end;
    grid-area: 2 / 1 / 4 / 2;
  }
  .dos {
    justify-self: start;
    grid-area: 2 / 2 / 4 / 3;
  }

  .tres {
    padding-top: 150px;
    justify-self: end;
    grid-area: 3 / 1 / 5 / 2;
  }

  .cuatro {
    padding-top: 150px;
    justify-self: start;
    grid-area: 3 / 2 / 5 / 3;
  }

  .cinco {
    padding-top: 150px;
    justify-self: end;
    grid-area: 4 / 1 / 6 / 2;
  }

  .seis {
    padding-top: 150px;
    justify-self: start;
    grid-area: 4 / 2 / 6 / 3;
  }

  .apple {
    justify-self: start;
    align-self: start;
    grid-area: 2 / 1 / 4 / 2;
  }

  .vec1 {
    justify-self: stretch;
    grid-area: 1 / 1 / 2 / 3;
    padding-top: 12px;

  }
  .vec1 img{
    width: 1920px;
    height: auto;
    margin-left: -400px;
  }

  .vec2 {
    margin-top: -150px;
    justify-self: stretch;
    grid-area: 6 / 1 / 7 / 3;
  }

  .vec2 img{
     width: 1920px;
     height: auto;
     margin-left: -400px;
   }

  .apple{
    width: 298px;
    height: 388px;
    left: 0%;
    top: -8%;
  }
  .comp{
    top: -110px;
  }
  .containervec{
    gap: 5px 165px;
  }

  .items{
    max-height: 170px;
    max-width: 169px;
    padding: 20px;
    margin: 0px;
  }

  .items img{
    max-inline-size: 100%;
  }

  .testimonial{
    margin-top: -110px;
  }

  .pera {
    width: 300px;
    height: 377px;
    left: 720px;
    top: 270px;
  }

  .slick-list{
    padding-top: 0px;
  }

  .titleform{
    font-size: 42px;
    line-height: 63px;
    width: 600px;
    height: 66px;
    right: 150px;
  }

  .subform{
    width: 802px;
    height: 130px;
    right: 150px;
    margin-top: 3rem;
  }

  
  .form1 {margin-top: 5rem;}
  
  .section_form{ height: 115vh;}

  .auth-wrapper{
    width: 600px;
  }

  .form1{
    margin-left: -120px;
  }

  .widget-title{
    font-size: 14px;
  line-height: 21px;
  }

  .decoration,.decoration1{
    font-size: 14px;
    line-height: 16px;
  }


  .custitle1{  margin-top: 3.5rem;}

  .tit5{
    width: 643px;
    height: 150px;
    font-size: 24px;
    line-height: 36px;

  }
  .titp{
    width: 435px;
    font-size: 24px;

  }

  .elote{
    display: inline-table;
    left: 1.5rem;
    margin-top: 2.5rem;
    width: 85vw;
    height: 65vh;
  }
 
  .titlep_first,.titlep_second,.titlep_therd{
    font-size: 24px;
    line-height: 36px;
    width: 55vw;
  }

  .titlep_second{
    width: 54vw;
  }
  .exh{
    top: -110px;
    width: 627px;
    height: 786px;
    right: 24px;
  }

  .ind{
    top: -46px;
    width: 661px;
    height: 871px;
    left: -80px;
  }

  .ref{
    top: 50px;
    width: 720px;
    height: 907px;
    right: 25px;
  }

  .envio{
    top: 71px;
    width: 690px;
    height: 851px;
    left: -96px;
  }

  .titI,.titlep,.titf{
    width: 535px;
    margin-top: 0px;
  }

  .send{margin-left: -11.5rem;}

  .titE{
    width:55vw;
    line-height: 63px;
  }
  .titlep_therd{width: 52vw;}

  .title-exp{
    padding: 10px;
    width: 555px;
    top: 300px;
    height: 300px;
    right: 280px;
  }

  .title-ind{
    left: 210px;
    top: 426px;
    height: 250px;
  }

  .title-camera{
    right: 275px;
    top: 615px;
    height: 340px;
  }

  .slider_envio{
    margin-top: 16rem;
  }
  .title-envio{
    top: 600px;
    height: 310px;
    left: 390px;
  }
/* -------------------------------------------- */

.subtitle-terms{
 
  font-size: 2.9vw;
  width: 70vw;
}

.title-terms1{
  font-size: 4.6vw;
  margin-left: -8.5rem;
}
  .subtitle-terms1,.subtitle-terms2{
    font-size: 3.2vw;
    text-align: left;
    width: 84vw;
    right: 18.2%;
    line-height: 50px;
  }

  .subtitle-terms1{
    margin-top: 5rem;
  }
  .subtitle-terms2{ margin-top: 18rem;}

  .terms2{
    margin-top: 43rem;
    height: 50vh;
  }
  
  .title-terms2{
    margin-left: -12.5rem;
  }

  .subtitle-terms2{
    height: 0vh;
     width: 85vw;
     margin-top: 300px;
     right: 57%;

     }

  .candado{
  width: 620px;
  height: 235px;
  left: -20px;
  top: 140px;
  }

  .column{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .carretilla{
    width: 25rem;
    left:7rem;
    top: 15rem;
    }

    .subtitle-conditions{
      font-size: 2.8vw;
      width: 67vw;
      line-height: 36px;
    
    }
    
    .title-terms2{
    
      left: 40px;
    }


  }


@media screen and (min-width: 820px) and (max-width: 821px)  {
  .color_second{height: 70vh;}    

  .custitle{
    margin-top: 7rem;
  }

  .title_fructus{
    font-size: 7.5vw;
    margin-right: 1rem;
    margin-top: 1rem;
  }

  .cosecha{
    width: 112vw;
    top: -11.4rem;
    right: 4.9rem;
    margin-bottom: -110px;
  }

  .tit1{
    width: 36rem;
    margin-top: 2rem;
    margin-left: -1.5rem;
  }

  .tit2{
    padding-right: 0px;
    font-weight: 600;
    width: 21.8rem;
    margin-left: -1.5rem;
  }

  .conatiner-media{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-content: center;
    margin-top: 12rem;
  }

  .section-title-wrap_second{
    width: 70vw;
    height: 50vh;
    margin-left: 12.5rem;
  }
    
  .tit3{
    text-align: center;
    margin-top: -110px;
  }

  .tit4{
    width: 60vw;
    margin-left: 3rem;
    margin-top: 2.5rem;
    font-size: 3.1vw;
    line-height: 36px;
  }

  .canasta{
    top: -29rem;
    width: 55vw;
    height: 40vh;
    right: 8vw;
  }

  .vec1 img{
    margin-top: -38rem;
  }

  .comp{
    margin-top: -18rem;
  }

  .apple{
    top: -31rem;
    width:45vw;
    height: 30vh;
    left: -5.8rem;
  }


/* -------------------------------- */
.containervec {  
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 0.5fr 1fr 1fr 0.5fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  margin-top: -8rem;
  width: 100vw;
  margin-left: -4rem;
  height:150vh ;

}


.uno,.dos,.tres,.cuatro,.cinco,.seis{ width: 45vw;}

.uno {
  justify-self: center;
  align-self: stretch;
  grid-area: 3 / 1/ 1 / 4;
  margin-right: 26rem;
}

.dos {
  justify-self: center;
  grid-area: 3 / 4/ 1 / 2;
  margin-left: 8rem;
}

.tres {
  justify-self: center;
  grid-area: 3 / 1 / 4 / 3;
  margin-top: 3rem;
  margin-right: 8.8rem;
}

.cuatro {
  justify-self: center;
  grid-area: 3 / 2 / 4 / 4;
  margin-left: 7rem;
  margin-top: 3rem; 
}

.cinco {
  justify-self: center;
  grid-area: 5 / 1 / 5 / 3;
  margin-right: 8.8rem;
  margin-top: -20rem;
}

.seis {
  justify-self: center;
  grid-area: 6 /2 / 5 / 4;
  margin-left: 7rem;
  margin-top: -20rem;
}

.vec2 {
 margin-top: -3rem;
}

/* --------------------------------- */
  .items{
    height: 9rem;
    margin-left: -13rem;
  }
    .items img{
      width: 15vw;
      height: 13vh;
      margin-top: -3rem;
    }

    .fleizq,.fleder{ width: 18rem;}

    .corrusel{
      margin-top: 5rem;
      width: 95vw;
      margin-left: -2.8rem;
    }

    /* .slick-list{   width: 100vw;}
    */
  .pera{
    width: 35vw;
    left: 32.5rem;
    top: 20rem;
  }

  .titleform {
    font-size: 42px;
    line-height: 63px;
    width: 600px;
    height: 66px;
    left: 50px;
    margin-top: 10rem;
}

.subform {
  width: 100vw;
  margin-top: 3rem;
  font-size: 3vw;
  left: 50px;
}

.form1 {margin-top: 9rem;}

.section_form{ height: 150vh;}

.coche {
  position: relative;
  top: -20px;
  width: 100%;
  margin-left: 34px;
}

.separation-social{
  left: 240px;

}

.apps{
  width: 320px;
  height: 380px;
  margin-top: 4rem;
  margin-left: -0.5rem;
}
/* ------------------------------------------------ */

.custitle1{margin-top: 9rem;}

.tit5{
  font-size: 3.1vw;
  width: 95vw;
  margin-top: 2.5rem;
  font-weight: 400;
}

.titp{
 font-weight: 600;
  margin-top: 3rem;
}

.elote {
  display: inline-table;
  left: 1rem;
  margin-top: 2.5rem;
}



.title-exp {
  padding: 30px;
  top: -14rem;
  height: 300px;
  right: -6.5rem;
}
.exh {
  top: -70px;
  width: 700px;
  height: 900px;
  right: 33px;
}

.titl{
  margin-left: -1.5rem;
  width: 58vw;
}

.titlep{font-size: 3.1vw;}

.ind {
  top: -3rem;
  width: 719px;
  height: 974px;
  left: 20px;
}

.title-ind {

  left: 115px;
  top:65rem;
  height: 250px;
}

.titlep_first,.titlep_second,.titlep_therd{
  font-size: 3.1vw;
  line-height: 36px;
}

.ref {
  top: 12rem;
  width: 95vw;
  height: 100vh;
  right: 5rem;
}

.titf{
  margin-top: 38rem;
}

.titlep_second{
  width: 37rem;
}

.envio {
  top: 1rem;
  width: 703px;
  height: 818px;
  left: 2.5rem;
}

.exportaciones{height: 150vh;}

.send{margin-left: -8.5rem;}

.titE{
  margin-top: 33rem;
  font-size: 40px;
  font-weight: 700;
  line-height: 63px;
}

.titlep_therd{ width: 35rem;}

.title-camera {
  top: -400px;
  height: 340px;
  left: 115px;
}


.title-envio {
  top: 850px;
  height: 310px;
  left: 230px;
}

.slider_envio{
  margin-top: 5rem;
}
/* ----------------------------- */

.title-privacity{
  margin-top: -5.5rem;
  font-size: 5vw;
  width: 55vw;
  height: 10vh;
}

.title-terms1{
  right: 0%;
}
.subtitle-terms{
  margin-top: 4.5rem;
  font-size: 2.5vw;
  width: 55vw;
}

.subtitle-terms1{
  width: 80vw;
right: 0%;
height: 680px;
}

.candado{
  width: 23rem;
  left: 13rem;
  top: -3.8rem;
  }

.subtitle-terms2{
 height: 35vh;
  width: 80vw;
  top: -150px;
  right: 0%;

  }

  .title-terms{
    margin-top: -5.5rem;
    font-size: 5vw;
    width: 60vw;
    height: 10vh;
  }
  .title-terms2{
    
    left: 0px;
  }


    .title_conditions{
      margin-top: -2rem;
    }
    .subtitle-conditions{
      margin-top: 4.5rem;
      font-size: 2.5vw;
      width: 52vw;
      line-height: 36px;
    }

    .carretilla{
      width: 20rem;
      left:28rem;
      top: -10rem;
      }

      .terms2{
        margin-top: 25rem;
      }
      .subtitle-conditions_2{
        margin-top: 4rem;
        height:45vh;
      }
    .color2{
      height: 50vh;
    }

}

@media screen and (min-width: 768px) and (max-width: 819px) {

   
  .color_second{
    height: 80vh;}    

  .custitle{
    margin-top: 7rem;
  }

  .title_fructus{
    font-size: 8vw;
    margin-right: 2rem;
    margin-top: 1rem;
  }

  .cosecha{
    width: 104vw;
    top: -11rem;
    right: 1.9rem;
    margin-bottom: -110px;
  }

  .tit1{
    width: 36rem;
    margin-top: 2rem;
    margin-left: .5rem;
  }

  .tit2{
    padding-right: 0px;
    font-weight: 600;
    width: 21.8rem;
    margin-left: .5rem;
  }

  .conatiner-media{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-content: center;
    margin-top: 13rem;
  }

  .section-title-wrap_second{
    width: 70vw;
    height: 45vh;
    margin-left: 13rem;
  }
    
  .tit3{
    font-size: 5.8vw;
    text-align: center;
    margin-top: -150px;
  }

  .tit4{
    width: 60vw;
    margin-left: 3rem;
    margin-top: 2.5rem;
    font-size: 3.1vw;
    line-height: 36px;
  }

  .canasta{
    top: -20rem;
    width: 65vw;
    height: 47vh;
    right: 8vw;
  }

  .vec1 img{
    margin-top: -34rem;
  }

  .comp{
    margin-top: -13rem;
    margin-left: 4rem;
  }

  .apple{
    top: -25rem;
    width:45vw;
    height: 30vh;
    left: -5.8rem;
  }


/* -------------------------------- */
.containervec {  
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 0.5fr 1fr 1fr 0.5fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  margin-top: -3rem;
  width: 100vw;
  margin-left: -4rem;
  height:150vh ;

}


.uno,.dos,.tres,.cuatro,.cinco,.seis{ width: 45vw;}

.uno {
  justify-self: center;
  align-self: stretch;
  grid-area: 3 / 1/ 1 / 4;
  margin-right: 26rem;
}

.dos {
  justify-self: center;
  grid-area: 3 / 4/ 1 / 2;
  margin-left: 8rem;
}

.tres {
  justify-self: center;
  grid-area: 3 / 1 / 4 / 3;
  margin-top: 3rem;
  margin-right: 8.8rem;
}

.cuatro {
  justify-self: center;
  grid-area: 3 / 2 / 4 / 4;
  margin-left: 7rem;
  margin-top: 3rem; 
}

.cinco {
  justify-self: center;
  grid-area: 5 / 1 / 5 / 3;
  margin-right: 8.8rem;
  margin-top: -16rem;
}

.seis {
  justify-self: center;
  grid-area: 6 /2 / 5 / 4;
  margin-left: 7rem;
  margin-top: -16rem;
}

.vec2 {
 margin-top: -3rem;
}

/* --------------------------------- */
  .items{
    height: 9rem;
    margin-left: -13rem;
  }
    .items img{
      width: 15vw;
      height: 13vh;
      margin-top: -3rem;
    }

    .fleizq,.fleder{ width: 18rem;}

    .corrusel{
   
      margin-top: 5rem;
      width: 95vw;
      margin-left: -1.3rem;
    }

    /* .slick-list{   width: 100vw;}
    */
  .pera{
    width: 35vw;
    left: 32.5rem;
    top: 20rem;
  }

  .titleform {
    font-size: 42px;
    line-height: 63px;
    width: 600px;
    height: 66px;
    left: 50px;
    margin-top: 10rem;
}

.subform {
  width: 100vw;
  margin-top: 3rem;
  font-size: 3vw;
  left: 50px;
}

.form1 {margin-top: 9rem;}

.section_form{ height: 175vh;}

/* --------------------------------------------------- */
.custitle1{margin-top: 9rem;}

.tit5{
  font-size: 3.1vw;
  width: 95vw;
  margin-top: 2.5rem;
  font-weight: 400;
}

.titp{
 font-weight: 600;
  margin-top: 3rem;
}

.elote {
  display: inline-table;
  left: .5rem;
  margin-top: 2.5rem;
}

/* .apps{
  margin-top: 4rem;
  width:71vw;
  height: 56vh;
  margin-left: -19.5rem;
} */

.title-exp {
  padding: 30px;
  top: -14rem;
  height: 300px;
  right: -6.5rem;
}
.exh {
  top: -70px;
  width: 700px;
  height: 900px;
  right: 33px;
}

.titlep{
  font-size: 3.1vw;
  width: 73vw;
  margin-left: -1rem;
}


.titl{
  margin-left: -1.5rem;
  width: 58vw;
}


.ind {
  top: 2rem;
  width: 719px;
  height: 974px;
  left: 20px;
}

.title-ind {
  left: 6rem;
  top:68rem;

}

.titlep_first{
  width: 70vw;
}

.titlep_first,.titlep_second,.titlep_therd{
  font-size: 3.1vw;
  line-height: 36px;
}

.ref {
  top: 17rem;
  width: 95vw;
  height: 100vh;
  right: 3rem;
}

.titf{
  margin-top: 48rem;
  margin-left: -2.8rem;
}

.titlep_second{
  width: 35rem;
  margin-left: -2.8rem;
}

.envio {
  top: 11rem;
  width: 703px;
  height: 818px;
  left: 2.5rem;
}

.exportaciones{height: 150vh;}

.send{margin-left: -6.5rem;}

.titE{
  margin-top: 45rem;
  font-size: 40px;
  font-weight: 700;
  line-height: 63px;
}

.titlep_therd{ width: 33rem;}

.title-camera {
  top: -400px;
  height: 340px;
  left: 140px;
}

.title-envio {
  top: 855px;
  height: 310px;
  left: 210px;
}

.slider_envio{
  margin-top: 25rem;
}
/* ----------------------------------------------- */

.title-privacity{
  margin-top: -5.5rem;
  font-size: 5vw;
  width: 55vw;
  height: 10vh;
  margin-left: 2rem;
}

.title-terms1{
  right: 0%;
}
.subtitle-terms{
  margin-left: 2rem;
  margin-top: 5rem;
  font-size: 2.5vw;
  width: 55vw;
}

.subtitle-terms1{
  width: 80vw;
right: 0%;
}

.candado{
  width: 20rem;
  left: 13.5rem;
  top: -4.8rem;
  }

.subtitle-terms2{
  margin-left: 3rem;
 height: 35vh;
  width: 80vw;
  top: -150px;

  }

  .title-terms{
    margin-top: -5.5rem;
    font-size: 5vw;
    width: 60vw;
    height: 10vh;
  }
  .title-terms2{
    
    left: 0px;
  }


    .title_conditions{
      margin-left: 2rem;
      margin-top: -2rem;
    }
    .subtitle-conditions{
      margin-top: 5.5rem;
      font-size: 2.5vw;
      width: 52vw;
      line-height: 36px;
    }

    .carretilla{
      width: 18rem;
      left:28rem;
      top: -10rem;
      }

      .terms2{
        margin-top: 25rem;
      }
      .subtitle-conditions_2{
        margin-top: 4rem;
        height:45vh;
      }
    .color2{
      height: 53vh;
    }

  .separation-social{
    left: 180px;

  }
  .carriot {

    position: relative;
    width: 1150px;
    height: 160px;
    margin-top: -90px;
}


}

/*******IPHONE*******/






