@media screen and (min-width:540px) and (max-width:768px){
  
  .apps{
    width: 290px;
    height: 334px;
  }

  .color3{
      
    width: 100vw;
    height: 55vh;
  }
  
  .how{

      width: 95%;
     font-size:8vw ;
     margin-top: -5rem;
     margin-left: 2rem;
  }

  .how_p{   
  
      font-size: 3vw;
      height:2rem ;
      margin-top: 2rem;
      width: 82%;
  }

  .team-img img{
      position: absolute;
      width: 33vw;
      margin-left: 11rem;
      margin-top: -5rem;    
  }
  
  .title_team{        
    font-size: 4.5vw;
     padding-bottom: 1rem;
    margin-left: -1rem;
  }

  .row_card{display: contents;}

   .card-deck_one,.card-deck_two{
    display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }

    .card-deck_three{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 1fr;
    }

    .card-deck_one{  
        margin-top:4rem ;
        gap: 2rem;
        height: 65vh;
      }
      
    .card-deck_two{
    
      gap: 2rem;
      height: 55vh;  
      margin-top:-3rem ;
    }

    .card-deck_three{
    
      gap: 2rem;
      height: 45vh;
      margin-top:11rem ;
    }

    .card_one,.card_two,.card_three,.card_four,
    .card_five,.card_six,.card_seven,.card_eight,
    .card_nine,.card_ten{
      width: 40vw;
      height:30vh;
    }

    .card-img-top{
      margin-bottom: 6rem;
      width:16vw;
      height:12vh;
    }

   .card-title{

      width: 39vw;
      margin-top: -6rem;
      font-size: 4vw;
      font-weight: 700;
   }
 
    .card-text{
    
      margin-top: 1rem;
      font-size: 3vw;
      line-height: 20px;
      width:38vw ;
    }

    .card-deck_two > *,
    .card-deck_one > *,
    .card-deck_three > *{      
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 3.1rem;

    }


}

@media screen and (min-width:820px) and (max-width:912px){

  .color3{
    width: 100vw;
    height: 49vh;
  }
 
  
  .how{
      width: 95%;
     font-size:8vw ;
     margin-top: -5rem;
     margin-left: 1rem;
  }

  .how_p{   
      font-size: 3vw;
      height:2rem ;
      margin-top: 2rem;
      width: 82%;
      margin-left: 1rem;
  }

  .team-img img{
      position: absolute;
      width: 33vw;
      margin-left: 12rem;
      margin-top: -5rem;    
  }
  
  .title_team {          
    font-size: 4.8vw;
     padding-bottom: 1rem;
    margin-left: -2rem;
  }

  .row_card{
   
    display: contents;}

   .card-deck_one,.card-deck_two{
  
    display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }

    .card-deck_three{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 1fr;
    }

    .card-deck_one{ 
    
        margin-top:4rem ;
        gap: 2rem;
        height: 70vh;
        margin-left: -2rem;
      }
      
    .card-deck_two{
      margin-left: -2rem;
      gap: 2rem;
      height: 55vh;  
      margin-top:-5rem ;
    }

    .card-deck_three{
      margin-left: -2rem;
      gap: 2rem;
      height: 45vh;
      margin-top:11.7rem ;
    }

    .card_one,.card_two,.card_three,.card_four,
    .card_five,.card_six,.card_seven,.card_eight,
    .card_nine,.card_ten{
      width: 40vw;
      height:30vh;
    }

    .card_five,.card_six,
     .card_nine,.card_ten{
      margin-top: -1.8rem;
    }

    .card-img-top{
      margin-bottom: 4.5rem;
      width:16vw;
      height:12vh;
    }

   .card-title{

      width: 39vw;
      margin-top: -4.5rem;
      font-size: 4vw;
      font-weight: 700;
   }
 
    .card-text{
      margin-top: 1.5rem;
      font-size: 3.5vw;
      line-height: 20px;
      

    }

    .card-deck_two > *,
    .card-deck_one > *,
    .card-deck_three > *{    
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -2rem;

    }


}

@media screen and (min-width:1024px) and (max-width:1025px)    {

  .color3{

    width: 100vw;
    height: 49vh;
  }
 
 
  .how{
      width: 80vw;
     font-size:8vw ;
     margin-top: -1rem;
     margin-left: 1rem;
  }

  .how_p{   
      font-size: 3vw;
      height:2rem ;
      margin-top: 2rem;
      width: 82vw;
      margin-left: 1rem;
  }

  .team-img img{
      position: absolute;
      width: 33vw;
      margin-left: -7rem;
      margin-top: 1rem;    
  }
  
  .title_team {          
    font-size: 4.8vw;
     padding-bottom: 1rem;
    margin-left: -2rem;
  }

  .row_card{
   
    display: contents;}

   .card-deck_one,.card-deck_two{
  
    display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }

    .card-deck_three{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 1fr;
    }

    .card-deck_one{ 
    
        margin-top:4rem ;
        gap: 4rem;
        height: 73vh;
        margin-right: -1rem;
      }
      
    .card-deck_two{
      margin-right: -1rem;
      gap: 4rem;
      height: 55vh;  
      margin-top:-6rem ;
    }

    .card-deck_three{
      margin-right: -1rem;
      gap: 4rem;
      height: 39vh;
      margin-top:20rem ;
    }

    .card_one,.card_two,.card_three,.card_four,
    .card_five,.card_six,.card_seven,.card_eight,
    .card_nine,.card_ten{
      width: 40vw;
      height:30vh;
    }

    .card_five,.card_six,
     .card_nine,.card_ten{
      margin-top: -4.6rem;
    }

    .card-img-top{
      margin-bottom: 4.5rem;
      width:19vw;
      height:15vh;
    }

   .card-title{
      width: 39vw;
      margin-top: -3rem;
      font-size: 3.5vw;
      font-weight: 700;
   }
 
    .card-text{
      margin-top: 2rem;
      font-size: 2.8vw;
      line-height: 20px;
    }

    .card-deck_two > *,
    .card-deck_one > *,
    .card-deck_three > *{    
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -2rem;

    }
}

  
/* ****mobile****** */

@media screen and (min-width:280px) and (max-width:359px){

  .color3{
      width: 100vw;
      height: 65vh;
    }

    .how{
      
        width: 90%;
       font-size:8vw ;
       margin-top: -9rem;
       margin-left: 1rem;
 
    }

    .how_p{   
        margin-left: 1rem;
        font-size: 3.7vw;
        height:2rem ;
        margin-top: -3rem;
        width: 68%;
    }

    .team-img img{
        position: absolute;
        width: 60vw;
        margin-left: -14rem;
        margin-top: 1rem;    
    }

    .row_card{display: contents;}

     .card-deck_one,.card-deck_two{
      display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr 1fr 1fr;
      }

      .card-deck_three{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr;
      }

      .card-deck_three{
        height: 35vh;
        margin-top:-.5rem ;
      }

      .card-deck_one{  height: 65vh;}

      .card-deck_two{
        height: 55vh;  
        margin-top:-12rem ;
      }

      .card_one,.card_two,.card_three,.card_four,
      .card_five,.card_six,.card_seven,.card_eight,
      .card_nine,.card_ten{
        width: 40vw;
        height:20vh;
      }
      .card-img-top{
        margin-bottom: 3rem;
        width:20vw;
        height:11vh;
      }

     .card-title{
        width: 39vw;
        margin-top: -4rem;
        font-size: 4vw;
        font-weight: 700;
     }
   
      .card-text{
        margin-top: -1rem;
        font-size: 2.7vw;
        line-height: 20px;
      }
  
      .card-deck_two > *,
      .card-deck_one > *,
      .card-deck_three > *{      
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2.1rem;

      }

      .title{ padding-bottom: 1rem;}
  
  }
  

  @media screen and (min-width:360px) and (max-width:374px) {
    .color3{
      width: 100vw;
      height: 65vh;
    }
    .card-deck_three{
     
      height: 35vh;
      margin-top:1rem ;
    }

    .card-deck_one{  
    
        margin-top:7rem ;
        height: 65vh;}

    .card-deck_two{
   
      height: 55vh;  
      margin-top:-11rem ;
    }
    
    .how{
      width: 95%;
     font-size:9vw ;
     margin-top: -12rem;
     margin-left: 1rem;
  }

  .how_p{   
  
      margin-right: -0.5rem;
      font-size: 4.4vw;
      height:2rem ;
      margin-top: -2.6rem;
      width: 82%;
  }

  .team-img img{
      position: absolute;
      width: 60vw;
      margin-left: -13.3rem;
      margin-top: -10rem;    
  }

  .row_card{display: contents;}

   .card-deck_one,.card-deck_two{
    display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }

    .card-deck_three{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 1fr;
    }

    .card_one,.card_two,.card_three,.card_four,
    .card_five,.card_six,.card_seven,.card_eight,
    .card_nine,.card_ten{
      width: 40vw;
      height:22.5vh;
    }
    .card-img-top{
      margin-bottom: 3rem;
      width:19vw;
      height:10vh;
    }

   .card-title{
      width: 39vw;
      margin-top: -4rem;
      font-size: 4vw;
      font-weight: 700;
   }
 
    .card-text{
      margin-top: -1rem;
      font-size: 2.7vw;
      line-height: 20px;
    }

    .card-deck_two > *,
    .card-deck_one > *,
    .card-deck_three > *{      
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2.7rem;

    }

    .title{ padding-bottom: 1rem;}

/* ------------------------------------------------ */

    .color{
      height: 88vh;
    }

    .title{
       height: 4rem;
       top: 5rem;
       margin-left: .5rem;
      }

      .custitle{
        margin-bottom: 1rem;
        margin-right: 4.9em;
      }

      .custom-primary{
    margin-right: 6.6em;
    margin-top:-7rem;
  }
      .tit1{
        
        line-height: 22px;
        font-size: 3.6vw;
        top: 10rem;
        text-align: justify;
        margin-left: -2.7rem;
        width: 76vw;
      }
      
      .tit2 {
        width: 85vw;
        margin-top: 7rem;
        font-size: 3.6vw;
        line-height: 25px;
        text-align: left;
        margin-left: -.8rem;
      }

      .cosecha {
        width: 160vw;
        height: 30vh;
        margin-top: -10.5rem;
        margin-left: 15rem;
      }
      
  .tit3{
    margin-right: -1rem;
    font-size: 8.1vw;
    width: 100%;
      margin-top: -1rem;
  }
  .tit4{
    margin-top: -1rem;
    font-size:3.7vw;
    line-height: 21px;
    padding-left: 0.5em;
    width: 25.4em;
    text-align:left;
  }
    
  .canasta{
    margin-top:48rem;
    width: 100vw;
    height: 50vh;
    right: 2.5em;
  }
  
  .vec1,.vec2{
    width: 100vw;
    height: 10vh;
  }
  .vec1 img{  top:-15rem;}
  
  .apple{
    width: 35vw;
    height: 35vh;
     top: -30rem;
     left: -16px;
  }
  .comp{
    margin-top: -5.5rem;
  }

  .uno{ margin-top: -5em; }
  
  .dos{  margin-top: 1em; }

  .containervec{
    margin-top: -5rem;
    display: flex;
    gap:4.5rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .vec2 img{
    margin-top:123rem;}
  
  .vec1 img,.vec2 img{
    position: absolute;
    width: 200vw;
    height: 20vh;
    left: 10em;
  }
  
  .titcarrusel{
    margin-top: 118rem;
    font-size: 8.9vw;
    margin-left: -.5rem;
  }

  .corrusel{
    margin-top: 122rem;
  }
.items{
  height: 1rem;
}
 
.items img{
  position: absolute;
  width: 14.9vw;
  height: 6.9vh;
  margin-left: 3.1rem;
  margin-top: -1.5rem;
}

.fleder,.fleizq{
  width: 43vw;
  padding: 0;

  }
  
.testimonial{ 
  margin-top: -5rem;
  margin-left: -1rem;
  height: 80vh;
}

.pera{
  width: 53vw;
  margin-left: -88rem;
  margin-top: -10rem;
}

.ct-testimonials-slider{bottom:32em;}

  .centered-arrows{
    position: relative;
    bottom: 35rem;
    }
  
  .container_form-title{
  display:grid;
  grid-auto-rows: 15rem;
  grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
  margin: 0 .5em 0 .5em ;
  height: 25em;
  margin-top: -36rem;
  }
  
  
  .titleform{
    font-size:  8.1vw;
    width:69.6vw;
    margin-top:-2em;
    padding-right: 2.3rem;
    line-height: 48px;
    }
    
    .subform{
    line-height: 25px;
    width:90vw;
    font-size:3.7vw;
    line-height: 25px;
    top: -5rem;
    margin-left: -1.4rem;
    }
    
  .section_form{
    height: 100vh;
   
    }
    .auth-form{ 
      margin-top: -6rem;
    position: relative;
    width: 90vw;
    margin-left: .2rem;
    }

    .form1{width: 0;}


  .title,.tit1{ position: absolute;}

  .custitle, .title, .custitle1 {
     font-size:8.2vw; }

     .conatiner-media{
      height: 100vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-direction: column-reverse;
    }

    .conatiner-media{
        display:grid;
        grid-auto-rows: 15rem;
        grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
        margin: 0 .1em 0 .1em ;
         margin-top: 9rem;
      }

      .titlemedia{
        bottom: 11em;
        height:41em ;
        display: flex;
        justify-content: center;
      }
    
      
  .sect{
    display:grid;
    grid-auto-rows: 18rem;
    grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
    margin: 0 1em 0 1em ;
    top: 2rem;
    height: 20vh;
  }
  

/* ----------------------------------------------- */  
.custitle1{
top: 2em;
font-size:8.5vw;
left: .4em;
}

.tit5{
font-size: 3.7vw;
text-align: left;
margin-top: 11em;
width: 92.5vw;
line-height: 21px;
}

.titp{
font-size: 3.7vw;
font-weight: 600;
width: 100%;
}

.section-title-wrap{
  height:30em ;
  width: 24em;
}

  .section-title-wrap,.section-title-wrap-one{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
      margin-top: 2em;
  }
  
  .elote{
    width: 91vw;
    height: 65vh;
    }
    
    .titleapp{
    line-height: 48px;
    top: -1rem;
    height: 16vh;
    width: 100%;
    margin-left: -.8rem;
    }
    
    .container_slider-first{
    margin-top:-27em;
    margin-left: -.6rem;
    width: 20rem;
    }

    .apps{
      width:90vw;
      margin-left: -.1rem;
      height: 60vh; 
    }
    .container_slider-section{  margin-right: 5em; }
    
  .solutions{margin-top: -1rem;}
 
  .exh,.ind,.ref,.envio{
    width: 98vw;
    height: 65vh;
    }
    
    .exh{
    margin-top: -12.5rem;
    margin-left: 11.5rem;
    }

.titl,.titE{
      font-size:8.1vw;
      text-align: center;
      line-height: 48px;
      }

  .titl{ 
      width: 98.5%;
      margin-top: -17rem;
      margin-right: 3.5rem;
      }

.titlep{
  margin-left: -3rem;
  margin-top: -15.5rem;
}

.ind{
  margin-top: -11rem;
  margin-left: -3.5rem;
  }

  .titI,.titf{
    font-size:8.1vw;
    text-align: center;
    width: 97%;
    line-height: 48px;
    }
    
    .titI{
      margin-top: 29rem;
      margin-left: 13rem;
    }

.titlep,.titlep_first,.titlep_second,.titlep_therd{
  width: 100%;
  text-align: center;
  line-height:21px;
  font-size:3.7vw;
  }

  .titlep_first{
    margin-top: 55rem;
      margin-left: 13.5rem;

  }
  
  .ref{
    margin-left: 11.5rem;
    margin-top: -3rem;
    }

    .titf{ 
      margin-top: -11rem;
      margin-left: 14rem;
      }
          
  .titlep_second{
    margin-top: -35rem;
    margin-left: 14rem;
    }
    
     
  .title-ind{
  margin-top: -30vh;
  margin-left: -8em;
  }
    
  .title-exp{
    height: 20em;
    width: 90%;
    left: 1.2rem;
    }  

    .envio{
      margin-left: -4em;
      margin-top: -11.5rem;
      }
      .camera{
      height: 15vh;
      margin-top: 38vh;
      width: 100%;
      margin-left: -8.2em;
      }
      

.titE{ 
width: 80vw;
  margin-top: 29rem;
  margin-left: -.8rem;
  
}

.titlep_therd{
  margin-top: 63rem;
  margin-left: -1rem;
  }
  
 /* ----------------------------------------------- */

 .color2{
  width: 100vw;
  height: 69vh;
  }
  
 .title-privacity{
  margin-right: 29vw;
  font-size: 8.1vw;
  width: 94vw;
  line-height: 45px;
  margin-left: 7rem;
  }
  .subtitle-terms{
    margin-left: -.2rem;
    width: 90vw;
    font-size:3.7vw;
    line-height: 21px;
    }

    .candado{
      width:80vw;
      margin-top: -4rem;
      margin-left:-8.4rem ;
    }

    .title-terms1,.title-terms2{
      font-size: 5.5vw;
      margin-left: -1.5em;
      }
      
      .title-terms1{
      width: 60vw;
      margin-left: 1.5rem;
      }
    
      .subtitle-terms1{
        height: 105vh;
        font-size:3.1vw;
        line-height: 21px;
        text-align: left;
        width: 60vw;
        margin-left: 5.5rem;
        margin-top:1rem;
        }

  
        .subtitle-terms2{
          width: 60vw;
          font-size:3.1vw;
          line-height: 21px;
          text-align: left;
          margin-left: 8rem;
    
          }
          
.terms2{
margin-top: 21rem;
  height: 80vh;
}

.section_privacity{ height: 145vh;}

.title-terms{
  width: 60vw;
  margin-left: -4.5rem;
  font-size: 8.1vw;
  line-height: 54px;
  }

.subtitle-conditions{
  margin-left: 2rem;
  margin-top: -1rem;
width: 90vw;
font-size:3.7vw;
line-height: 21px;
}

  .carretilla{
  margin-left: -3rem;
  width: 51vw;
  margin-top: 1rem;
  }

  .title-terms2{ 
    margin-left: 1.5rem;
    width: 62vw;}

.subtitle-conditions_2{

  margin-top: -24.5rem;
}

.color4{
  height: 140vh;
}

  .section-title-wrap-one{
  height:18em ;
  width: 24.5em;
  }
  
  .exi-two{  height: 70vh;}
  
  .exi-two{margin-top: 25em;}
  
  .carousel-slider{
  width: 75vw;
  margin-left: 9vw;
  }
  
  .title-wrap{
  margin-top: -10vh;
  height: 40vh;
  width: 100vw;
  }
  
  .privacity_two{
  margin-top:-22rem;
  height: 55vh;
  }
  
  .privacity_three{
  margin-top:-10rem;
  height: 55vh;
  }
  
  .title_conditions{
  height: 25vh;
  margin-top: -3.5rem;
  }
  /* -------------------------------------- */

  .container_footer{ margin-top: 1rem; }
    
  .texts1,.textsucursal,.nameLabel,.Numberlabel,
  .NumberInput,.Messagelabel,
  .cityselector,.sucursalselector{
  font-size: 4vw;
  }
  
  .coche{right:1em;}
  
  .carriot{
  width: 150vw;
  height: 13vh;
  top: 1em;
  left: .5em;
  }
  
  .widget-title,
  .decoration,
  .decoration1,
  .footer-copyright p,
  .back-to-top i{
  font-size:3.7vw ;
  }
  .separation-social{ left: 0.4em; }
  
  
  }

  @media screen and (min-width:375px) and (max-width:376px) {
    .color3{
      width: 100vw;
      height: 70vh;
    }

    .card-deck_three{
      height: 35vh;
      margin-top:1.5rem ;
    }

    .card-deck_one{  
    
        margin-top:7rem ;
        height: 65vh;}

    .card-deck_two{
     
      height: 55vh;  
      margin-top:-10rem ;
    }
  
    .how{
      width: 95%;
     font-size:9vw ;
     margin-top: -12rem;
     margin-left: 1rem;
  }

  .how_p{   
  
      margin-right: -0.5rem;
      font-size: 4.4vw;
      height:2rem ;
      margin-top: -2.6rem;
      width: 82%;
  }

  .team-img img{
      position: absolute;
      width: 60vw;
      margin-left: -13.3rem;
      margin-top: -10rem;    
  }

  .row_card{display: contents;}

   .card-deck_one,.card-deck_two{
    display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }

    .card-deck_three{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 1fr;
    }

    .card_one,.card_two,.card_three,.card_four,
    .card_five,.card_six,.card_seven,.card_eight,
    .card_nine,.card_ten{
      width: 40vw;
      height:22.5vh;
    }
    .card-img-top{
      margin-bottom: 3rem;
      width:19vw;
      height:10vh;
    }

   .card-title{
      width: 39vw;
      margin-top: -4rem;
      font-size: 4vw;
      font-weight: 700;
   }
 
    .card-text{
      margin-top: -1rem;
      font-size: 2.7vw;
      line-height: 20px;
    }

    .card-deck_two > *,
    .card-deck_one > *,
    .card-deck_three > *{      
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2.7rem;

    }

    .title{ padding-bottom: 1rem;}

/* ------------------------------------------  */
    .color{
      height: 90vh;
    }

    .title{
     
       height: 4rem;
       top: 6rem;
       margin-left: .5rem;
      }

      .custitle{
        margin-bottom: 1rem;
        margin-right: 4.5em;
      }
  

      .custom-primary{
    margin-right: 6.3em;
    margin-top:-5.5rem;
  }
      .tit1{
        
        line-height: 22px;
        font-size: 3.6vw;
        top: 10.5rem;
        text-align: justify;
        margin-left: -2.7rem;
        width: 76vw;
      }
      
      .tit2 {  
        width: 85vw;
        margin-top: 6.5rem;
        font-size: 3.6vw;
        line-height: 25px;
        text-align: left;
        margin-left: -.8rem;
      }

      .cosecha {
        width: 160vw;
        height: 30vh;
        margin-top: -9.5rem;
        margin-left: 15.5rem;
      }
      
  .tit3{
    margin-right: -1rem;
    font-size: 8.1vw;
    width: 100%;
      margin-top: 1rem;
  }
  .tit4{
    margin-top: 5rem;
    font-size:3.7vw;
    line-height: 21px;
    padding-left: 0.5em;
    width: 25.4em;
    text-align:left;
  }
    
  .canasta{
    margin-top:55rem;
    width: 100vw;
    height: 55vh;
    right: 2.5em;
  }
  
  .vec1,.vec2{
    width: 100vw;
    height: 10vh;
  }
  .vec1 img{  top:-6rem;}
  
  .apple{
    width: 35vw;
    height: 35vh;
     top: -20rem;
  }
  .comp{
    margin-top: 4.3rem;
  }

  .uno{ margin-top: -5em; }
  
  .dos{  margin-top: 1em; }

  .containervec{
    margin-top: 8.5rem;
    display: flex;
    gap:4.5rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .vec2 img{
    margin-top:142rem;}
  
  .vec1 img,.vec2 img{
    position: absolute;
    width: 200vw;
    height: 20vh;
    left: 10em;
  }
  
  .titcarrusel{
    margin-top: 138rem;
    font-size: 8.9vw;
    margin-left: -.5rem;
  }

  .corrusel{
    margin-top: 144rem;
  }
.items{
  height: 1rem;
}
 
.items img{
  position: absolute;
  width: 14.9vw;
  height: 6.9vh;
  margin-left: 3.7rem;
  margin-top: -1.3rem;
}

.fleder,.fleizq{
  width: 43vw;
  padding: 0;

  }
  
.testimonial{ 
  margin-top: -5rem;
  margin-left: -1rem;
  height: 80vh;
}

.pera{
  width: 53vw;
  margin-left: -87rem;
  margin-top: -10rem;
}

.ct-testimonials-slider{bottom:32em;}

  .centered-arrows{
    position: relative;
    bottom: 35rem;
    }
  
  .container_form-title{
  display:grid;
  grid-auto-rows: 15rem;
  grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
  margin: 0 .5em 0 .5em ;
  height: 25em;
  margin-top: -36rem;
  }
  
  
  .titleform{
    font-size:  8.1vw;
    width:69.6vw;
    margin-top:-.5em;
    padding-right: 2.3rem;
    line-height: 48px;
    }
    
    .subform{
    line-height: 25px;
    width:90vw;
    font-size:3.7vw;
    line-height: 25px;
    top: -5rem;
    margin-left: -.5rem;
    margin-top: 4rem;
    }
    
  .section_form{
    height: 120vh;
   
    }
    .auth-form{ 
      margin-top: -3rem;
    position: relative;
    width: 90vw;
    margin-left: .2rem;
    }

    .form1{width: 0;}


  .title,.tit1{ position: absolute;}

  .custitle, .title, .custitle1 {
     font-size:8.2vw; }

     .conatiner-media{
      height: 100vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-direction: column-reverse;
    }

    .conatiner-media{
        display:grid;
        grid-auto-rows: 15rem;
        grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
        margin: 0 .1em 0 .1em ;
         margin-top: 9rem;
      }

      .titlemedia{
        bottom: 11em;
        height:41em ;
        display: flex;
        justify-content: center;
      }
    
      
  .sect{
    display:grid;
    grid-auto-rows: 18rem;
    grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
    margin: 0 1em 0 1em ;
    top: 2rem;
    height: 20vh;
  }
  

/* ----------------------------------------------- */  
.custitle1{
top: 2em;
font-size:8.5vw;
left: .4em;
}

.tit5{
font-size: 3.7vw;
text-align: left;
margin-top: 11em;
width: 92.5vw;
line-height: 21px;
}

.titp{
font-size: 3.7vw;
font-weight: 600;
width: 100%;
}

.section-title-wrap{
  height:30em ;
  width: 24em;
}

  .section-title-wrap,.section-title-wrap-one{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
      margin-top: 2em;
  }
  
  .elote{
    width: 91vw;
    height: 65vh;
    }
    
    .titleapp{
    line-height: 48px;
    top: 4rem;
    height: 16vh;
    width: 100%;
    margin-left: -.5rem;
    }
    
    .container_slider-first{
    margin-top:-22em;
    margin-left: .7rem;
    width: 20rem;
    }

    .apps{
      width:80vw;
      margin-left: -.1rem;
      height: 59vh; 
    }
    .container_slider-section{  margin-right: 5em; }
    
  .solutions{margin-top: -3rem;}
 
  .exh,.ind,.ref,.envio{
    width: 98vw;
    height: 65vh;
    }
    
    .exh{
    margin-top: -13.5rem;
    margin-left: 11.5rem;
    }

.titl,.titE{
      font-size:8.1vw;
      text-align: center;
      line-height: 48px;
      }

  .titl{ 
      width: 98.5%;
      margin-top: -20rem;
      margin-right: 3rem;
      }

.titlep{
  margin-left: -2.3rem;
  margin-top: -17.5rem;
}

.ind{
  margin-top: -11.5rem;
  margin-left: -3.5rem;
  }

  .titI,.titf{
    font-size:8.1vw;
    text-align: center;
    width: 97%;
    line-height: 48px;
    }
    
    .industry{
      margin-top: -2rem;

    }
    .titI{
      margin-top: 22rem;
      margin-left: 13.5rem;
    }

.titlep,.titlep_first,.titlep_second,.titlep_therd{
  width: 100%;
  text-align: center;
  line-height:21px;
  font-size:3.7vw;
  }

  .titlep_first{
    margin-top: 52rem;
      margin-left: 14rem;
  }
  
  .ref{
    margin-left: 11.5rem;
    margin-top: -3.5rem;
    }

    .titf{ 
      margin-top: -13rem;
      margin-left: 14rem;
      }
          
  .titlep_second{
    margin-top: -30rem;
    margin-left: 14.3rem;
    }
    
     
    .envio{
      margin-left: -4em;
      margin-top: -12rem;
      }

  .title-ind{
  margin-top: -30vh;
  margin-left: -8em;
  }
    
  .title-exp{
    height: 20em;
    width: 90%;
    left: 1.2rem;
    }  

      .camera{
      height: 15vh;
      margin-top: 38vh;
      width: 100%;
      margin-left: -8.2em;
      }
      

.titE{ 
width: 80vw;
  margin-top: 26rem;
  margin-left: -.1rem;
  
}

.titlep_therd{
  margin-top: 60rem;
  margin-left: .5rem;
  }
  
  .slider_envio_first{
    margin-top: -1rem;
  }
 /* ----------------------------------------------- */

 .color2{
  width: 100vw;
  height: 72vh;
  }
  
 .title-privacity{
  margin-right: 29vw;
  font-size: 8.1vw;
  width: 90vw;
  line-height: 45px;
  margin-left: 7rem;
  }
  .subtitle-terms{
    margin-left: .5rem;
    width: 90vw;
    font-size:3.7vw;
    line-height: 21px;
    }

    .candado{
      width:68vw;
      margin-top: -3.5rem;
      margin-left:-7.4rem ;
    }

    .title-terms1{
      font-size: 5.5vw;
      margin-left: -1.5em;
      }

      .title-terms2{
        font-size: 5.5vw;
        margin-left: 1.5em;
        width: 62vw;
        }
      
      .title-terms1{
      width: 60vw;
      margin-left: 1.5rem;
      }
    
      .subtitle-terms1{
        height: 105vh;
        font-size:3.1vw;
        line-height: 21px;
        text-align: left;
        width: 60vw;
        margin-left: 5.5rem;
        margin-top:1rem;
        }

  
        .subtitle-terms2{
          width: 60vw;
          font-size:3.1vw;
          line-height: 21px;
          text-align: left;
          margin-left: 8rem;
    
          }
          
.terms2{
margin-top: 25rem;
  height: 80vh;
}

.section_privacity{ height: 165vh;}

.title-terms{
  width: 60vw;
  margin-left: -4.5rem;
  font-size: 8.1vw;
  line-height: 54px;
  }

.subtitle-conditions{
  margin-left: 3rem;
  margin-top: -1rem;
width: 90vw;
font-size:3.7vw;
line-height: 21px;
}

  .carretilla{
  margin-left: -3rem;
  width: 48vw;
  margin-top: 1rem;
  }



.subtitle-conditions_2{

  margin-top: -33.5rem;
}

.color4{
  height: 168vh;
}

  .section-title-wrap-one{
  height:18em ;
  width: 24.5em;
  }
  
  .exi-two{  height: 70vh;}
  
  .exi-two{margin-top: 25em;}
  
  .carousel-slider{
  width: 75vw;
  margin-left: 9vw;
  }
  
  .title-wrap{
  margin-top: -10vh;
  height: 40vh;
  width: 100vw;
  }
  
  .privacity_two{
  margin-top:-22rem;
  height: 55vh;
  }
  
  .privacity_three{
  margin-top:-10rem;
  height: 55vh;
  }
  
  .title_conditions{
  height: 25vh;
  margin-top: -3.5rem;
  }

  .coche{
    margin-left: 13px;
    top: -19px;
  }

  .carriot{
    width: 572px;
    height: 97px;
  }
  /* -------------------------------------- */
    
}
@media screen  and (min-height:812px)  and (max-height:922px){

  
  .ind{
    margin-top: -11rem;
  }
  
  .ref{
  margin-top: -3rem;
  }
  .titI{
    margin-top: 28rem;
  }
  
  .titlep_first{
  margin-top: 57rem;
  
  }
  
  .ref{
    margin-top: -7rem;
  }
  
  .titf{
    margin-top: -14rem;
  }
  
  .titlep_second{
    margin-top: -47rem;
  }
  .envio{
    margin-top: -15rem;
  }
  
  } 

  @media screen and (min-width:377px) and (max-width:389px){
    .color3{
      width: 100vw;
      height: 64vh;
    }

    .how{
        width: 95%;
       font-size:9vw ;
       margin-top: -12rem;
       margin-left: 1rem;
    }

    .how_p{   
       position: absolute;
        margin-right: .5rem;
        font-size: 4.4vw;
        height:2rem ;
        margin-top: -2.6rem;
        width: 82vw;
    }

    .team-img img{
        position: absolute;
        width: 55vw;
        margin-left: -11.9rem;
        margin-top: -10rem;    
    }

    .row_card{display: contents;}

     .card-deck_one,.card-deck_two{
      display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr 1fr 1fr;
      }

      .card-deck_three{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr;
      }

      .card-deck_three{
        
        height: 35vh;
        margin-top:-1.4rem ;
      }

      .card-deck_one{  
          margin-top:6rem ;
          height: 65vh;}

      .card-deck_two{
        height: 55vh;  
        margin-top:-15rem ;
      }

      .card_one,.card_two,.card_three,.card_four,
      .card_five,.card_six,.card_seven,.card_eight,
      .card_nine,.card_ten{
        width: 40vw;
        height:20vh;
      }
      .card-img-top{
        margin-bottom: 3rem;
        width:20vw;
        height:10vh;
      }

     .card-title{
        width: 39vw;
        margin-top: -4rem;
        font-size: 4vw;
        font-weight: 700;
     }
   
      .card-text{
        margin-top: -1rem;
        font-size: 2.7vw;
        line-height: 20px;
      }
  
      .card-deck_two > *,
      .card-deck_one > *,
      .card-deck_three > *{      
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2.8rem;

      }

      .title{ padding-bottom: 1rem;}

/* --------------------------------------------------------- */

      .color{
        height: 85vh;
      }
  
      .title{
         height: 3rem;
         top: 6rem;
         margin-left: 1rem;
        }
  
        .custitle{
          margin-bottom: 4rem;
          margin-right: 4.9em;
        }
    
        .custom-primary{
      position:absolute;
      margin-right: 6.3em;
      bottom:14.5em;
    }
        .tit1{
          
          line-height: 22px;
          font-size: 3.6vw;
          top: 10.5rem;
          text-align: justify;
          margin-left: -2.7rem;
          width: 76vw;
        }
        
        .tit2 {
          width: 85vw;
          margin-top: 7rem;
          font-size: 3.6vw;
          line-height: 25px;
          text-align: left;
          margin-left: -.8rem;
        }
  
        .cosecha {
          width: 160vw;
          height: 30vh;
          margin-top: -9.5rem;
          margin-left: 15.5rem;
        }
        
    .tit3{
      margin-right: -1rem;
      font-size: 8.1vw;
      width: 100%;
        margin-top: 1rem;
    }
    .tit4{
      margin-top: 5rem;
      font-size:3.7vw;
      line-height: 21px;
      padding-left: 0.5em;
      width: 25.4em;
      text-align:left;
    }
      
    .canasta{
      margin-top:58rem;
      width: 100vw;
      height: 55vh;
      right: 2.5em;
    }
    
    .vec1,.vec2{
      width: 100vw;
      height: 10vh;
    }
    .vec1 img{  top:-6rem;}
    
    .apple{
      width: 35vw;
      height: 35vh;
       top: -20rem;
    }
    .comp{
      margin-top: 2.6rem;
    }
  
    .uno{ margin-top: -5em; }
    
    .dos{  margin-top: 1em; }
  
    .containervec{
      margin-top: 8.5rem;
      display: flex;
      gap:4.5rem;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .vec2 img{
      margin-top:147rem;}
    
    .vec1 img,.vec2 img{
      position: absolute;
      width: 200vw;
      height: 20vh;
      left: 10em;
    }
    
    .titcarrusel{
      margin-top: 138rem;
      font-size: 8.9vw;
      margin-left: -.5rem;
    }
  
    .corrusel{
      margin-top: 144rem;
    }
  .items{
    height: 1rem;
  }
   
  .items img{
    position: absolute;
    width: 14.9vw;
    height: 6.9vh;
    margin-left: 4.5rem;
    margin-top: -1.3rem;
  }
  
  .fleder,.fleizq{
    width: 43vw;
    padding: 0;
  
    }
    
  .testimonial{ 
    margin-top: -5rem;
    margin-left: -1rem;
    height: 80vh;
  }
  
  .pera{
    width: 53vw;
    margin-left: -87rem;
    margin-top: -10rem;
  }
  
  .ct-testimonials-slider{bottom:32em;}
  
    .centered-arrows{
      position: relative;
      bottom: 35rem;
      }
    
    .container_form-title{
    display:grid;
    grid-auto-rows: 15rem;
    grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
    margin: 0 .5em 0 .5em ;
    height: 25em;
    margin-top: -36rem;
    }
    
    .testimonial{
      margin-left: 1.5rem;
    }
    .titleform{
      font-size:  8.1vw;
      width:69.6vw;
      margin-top:-2em;
      padding-right: 1rem;
      line-height: 48px;
      }
      
      .subform{
      line-height: 25px;
      width:90vw;
      font-size:3.7vw;
      line-height: 25px;
      top: -5rem;
      margin-left: .5rem;
      margin-top: 4rem;
      }
      
    .section_form{
      height: 120vh;
     
      }
      .auth-form{ 
        margin-top: -3rem;
      position: relative;
      width: 90vw;
      margin-left: .2rem;
      }
  
      .form1{width: 0;}
  
  
    .title,.tit1{ position: absolute;}
  
    .custitle, .title, .custitle1 {
       font-size:8.2vw; }
  
       .conatiner-media{
        height: 100vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-direction: column-reverse;
      }
  
      .conatiner-media{
          display:grid;
          grid-auto-rows: 15rem;
          grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
          margin: 0 .1em 0 .1em ;
           margin-top: 9rem;
        }
  
        .titlemedia{
          bottom: 11em;
          height:41em ;
          display: flex;
          justify-content: center;
        }
      
        
    .sect{
      display:grid;
      grid-auto-rows: 18rem;
      grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
      margin: 0 1em 0 1em ;
      top: 2rem;
      height: 20vh;
    }
    
  
  /* ----------------------------------------------- */  
  .custitle1{
  top: 2em;
  font-size:8.5vw;
  left: -.1rem;
  }
  
  .tit5{
  font-size: 3.7vw;
  text-align: left;
  margin-top: 11em;
  width: 92.5vw;
  line-height: 21px;
  margin-left: 1rem;
  }
  
  .titp{
  font-size: 3.7vw;
  font-weight: 600;
  width: 100%;
  }
  
  .section-title-wrap{
    height:30em ;
    width: 24em;
  }
  
    .section-title-wrap,.section-title-wrap-one{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
        margin-top: 2em;
    }
    
    .elote{
      width: 91vw;
      height: 65vh;
      }
      
      .titleapp{
      line-height: 48px;
      top: 3rem;
      height: 16vh;
      width: 100%;
      margin-left: 1.3rem;
      }
      
      .container_slider-first{
      margin-top:-22rem;
      margin-left: .7rem;
      width: 20rem;
      }
  
      .apps{
        width:80vw;
        margin-left: .4rem;
        height: 57vh; 
      }
      .container_slider-section{  margin-right: 5em; }
      
    .solutions{margin-top: -4rem;}
   
    .exh,.ind,.ref,.envio{
      width: 98vw;
      height: 65vh;
      }
      
      .exh{
      margin-top: -12.5rem;
      margin-left: 11.5rem;
      }
  
  .titl,.titE{
        font-size:8.1vw;
        text-align: center;
        line-height: 48px;
        }
  
    .titl{ 
        width: 98.5%;
        margin-top: -18rem;
        margin-right: 2rem;
        }
  
  .titlep{
    margin-left: -1.5rem;
    margin-top: -15.5rem;
  }
  
  .ind{
    margin-top: -11.5rem;
    margin-left: -3.5rem;
    }
  
    .titI,.titf{
      font-size:8.1vw;
      text-align: center;
      width: 97%;
      line-height: 48px;
      }
      
      .titI{
        margin-top: 27rem;
        margin-left: 14.5rem;
      }
  
  .titlep,.titlep_first,.titlep_second,.titlep_therd{
    width: 100%;
    text-align: center;
    line-height:21px;
    font-size:3.7vw;
    }
  
    .titlep_first{
      margin-top: 54rem;
        margin-left: 15rem;
    }
    
    .ref{
      margin-left: 11.5rem;
      margin-top: -3rem;
      }
  
      .titf{ 
        margin-top: -12rem;
        margin-left: 14rem;
        }
            
    .titlep_second{
      margin-top: -33rem;
      margin-left: 14rem;
      }
      
       
      .envio{
        margin-left: -4em;
        margin-top: -11rem;
        }
  
    .title-ind{
    margin-top: -30vh;
    margin-left: -8em;
    }
      
    .title-exp{
      height: 20em;
      width: 90%;
      left: 1.2rem;
      }  
  
        .camera{
        height: 15vh;
        margin-top: 38vh;
        width: 100%;
        margin-left: -8.2em;
        }
        
  
  .titE{ 
  width: 80vw;
    margin-top: 30rem;
    margin-left: 1rem;
    
  }
  
  .titlep_therd{
    margin-top: 70rem;
    margin-left: 1.3rem;
    }
    
    .slider_envio_first{
      margin-top: 3rem;
    }
   /* ----------------------------------------------- */
  
   .color2{
    width: 100vw;
    height: 72vh;
    }
    
   .title-privacity{
    margin-right: 29vw;
    font-size: 8.1vw;
    width: 90vw;
    line-height: 45px;
    margin-left: 7rem;
    }
    .subtitle-terms{
      margin-left: .5rem;
      width: 90vw;
      font-size:3.7vw;
      line-height: 21px;
      }
  
      .candado{
        width:68vw;
        margin-top: -3.5rem;
        margin-left:-7.4rem ;
      }
  
      .title-terms1,.title-terms2{
        font-size: 5.5vw;
        margin-left: -1.5em;
        }
        
        .title-terms1{
        width: 60vw;
        margin-left: 1.5rem;
        }
      
        .subtitle-terms1{
          height: 105vh;
          font-size:3.1vw;
          line-height: 21px;
          text-align: left;
          width: 60vw;
          margin-left: 5.5rem;
          margin-top:1rem;
          }
  
    
          .subtitle-terms2{
            width: 60vw;
            font-size:3.1vw;
            line-height: 21px;
            text-align: left;
            margin-left: 8rem;
      
            }
            
  .terms2{
  margin-top: 20rem;
    height: 80vh;
  }
  
  .section_privacity{ height: 150vh;}
  
  .title-terms{
    width: 60vw;
    margin-left: -4.5rem;
    font-size: 8.1vw;
    line-height: 54px;
    }
  
  .subtitle-conditions{
    margin-left: 3rem;
    margin-top: -1rem;
  width: 90vw;
  font-size:3.7vw;
  line-height: 21px;
  }
  
    .carretilla{
    margin-left: -3rem;
    width: 48vw;
    margin-top: 1rem;
    }
  
    .title-terms2{ 
      margin-left: -2.5rem;
      width: 62vw;}
  
  .subtitle-conditions_2{
  
    margin-top: -29.5rem;
  }
  
  .color4{
    height: 148vh;
  }
  
    .section-title-wrap-one{
    height:18em ;
    width: 24.5em;
    }
    
    .exi-two{  height: 70vh;}
    
    .exi-two{margin-top: 25em;}
    
    .carousel-slider{
    width: 75vw;
    margin-left: 9vw;
    }
    
    .title-wrap{
    margin-top: -10vh;
    height: 40vh;
    width: 100vw;
    }
    
    .privacity_two{
    margin-top:-22rem;
    height: 55vh;
    }
    
    .privacity_three{
    margin-top:-10rem;
    height: 55vh;
    }
    
    .title_conditions{
    height: 25vh;
    margin-top: -3.5rem;
    }
    /* -------------------------------------- */

  }
  
  @media screen and (min-width:390px) and (max-width:392px){

    .color3{
        width: 100vw;
        height: 55vh;
      }
  
      .how{
          width: 95%;
         font-size:9vw ;
         margin-top: -12rem;
         margin-left: 1rem;
      }
  
      .how_p{   
      
          margin-right: 65.5rem;
          font-size: 4.4vw;
          height:2rem ;
          margin-top: -2.6rem;
          width: 82%;
      }
  
      .team-img img{
          position: absolute;
          width: 60vw;
          margin-left: -12.9rem;
          margin-top: -15rem;    
      }
  
      .row_card{display: contents;}
  
       .card-deck_one,.card-deck_two{
        display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr 1fr 1fr 1fr;
        }
  
        .card-deck_three{
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr 1fr;
        }
  
        .card-deck_three{ 
          height: 35vh;
          margin-top:-2rem ;
        }
  
        .card-deck_one{  
            margin-top:6rem ;
            height: 65vh;}
  
        .card-deck_two{
            
          height: 55vh;  
          margin-top:-15rem ;
        }
  
        .card_one,.card_two,.card_three,.card_four,
        .card_five,.card_six,.card_seven,.card_eight,
        .card_nine,.card_ten{
          width: 40vw;
          height:20vh;
        }
        .card-img-top{
          margin-bottom: 3rem;
          width:20vw;
          height:10vh;
        }
  
       .card-title{
          width: 39vw;
          margin-top: -4rem;
          font-size: 4vw;
          font-weight: 700;
       }
     
        .card-text{
          margin-top: -1rem;
          font-size: 2.7vw;
          line-height: 20px;
        }
    
        .card-deck_two > *,
        .card-deck_one > *,
        .card-deck_three > *{      
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 3.1rem;
  
        }
  
        .title{ padding-bottom: 1rem;}

   /* ------------------------------------------------ */
      .color{
        height: 75vh;
      }
  
      .title{
        width: 80vw;
         height: 3rem;
         top: 6rem;
         margin-left: 8.5rem;
        }
  
        .custitle{       
          margin-bottom: 3.5rem;
          margin-right: 4.9em;
        }
    
        .custom-primary{      
      position:absolute;
      margin-right: 12.3rem;
      bottom:14.5em;
    }
        .tit1{
          
          line-height: 22px;
          font-size: 3.6vw;
          top: 10.5rem;
          text-align: justify;
          margin-left: -2.5rem;
          width: 76vw;
        }
        
        .tit2 {
          width: 85vw;
          margin-top: 7rem;
          font-size: 3.6vw;
          line-height: 25px;
          text-align: left;
          margin-left: -.3rem;
        }
  
        .cosecha {
          width: 155vw;
        height: 30vh;
        margin-top: -11.4rem;
        margin-left: 14.4rem;
        }
        
    .tit3{
    
      margin-right: 6.6rem;
      font-size: 8.1vw;
      width: 100%;
        margin-top: 1rem;
    }
    .tit4{
      margin-top: 5rem;
      font-size:3.7vw;
      line-height: 21px;
      padding-left: 0.5em;
      width: 25.4em;
      text-align:left;
    }
      
    .canasta{
      margin-top:65rem;
      width: 100vw;
      height: 50vh;
      right: 2.5em;
    }
    
    .vec1,.vec2{
      width: 100vw;
      height: 10vh;
    }
    .vec1 img{  top:-12rem;}
    
    .apple{
      width: 35vw;
      height: 35vh;
       top: -26rem;
    }
    .comp{
      margin-top: -2.6rem;
      margin-left: 2.5rem;
    }
    .containervec{
      margin-top: 2.5rem;
      display: flex;
      gap:4.5rem;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .uno{ margin-top: -5em; }
    
    .dos{  margin-top: 1em; }
  

    .vec2 img{
      margin-top:143rem;}
    
    .vec1 img,.vec2 img{
      position: absolute;
      width: 200vw;
      height: 20vh;
      left: 10em;
    }
    
    .titcarrusel{
      margin-top: 138rem;
      font-size: 8.9vw;
      margin-left: -.2rem;
      width: 100vw;
    }
  
    .corrusel{
      margin-top: 144rem;
    }
  .items{
    height: 1rem;
  }
   
  .items img{
    position: absolute;
    width: 14.9vw;
    height: 6.9vh;
    margin-left: 5rem;
    margin-top: -1.3rem;
  }
  
  .fleder,.fleizq{
    width: 43vw;
    padding: 0;
  
    }
    
  .testimonial{ 
    margin-top: -5rem;
    margin-left: -1rem;
    height: 80vh;
  }
  
  .pera{
    width: 53vw;
    margin-left: -87rem;
    margin-top: -10rem;
  }
  
  .ct-testimonials-slider{bottom:32em;}
  
    .centered-arrows{
      position: relative;
      bottom: 35rem;
      }
    
    .container_form-title{
    display:grid;
    grid-auto-rows: 15rem;
    grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
    margin: 0 .5em 0 .5em ;
    height: 25em;
    margin-top: -36rem;
    }
    
    .testimonial{
      margin-left: 1.5rem;
    }
    .titleform{
      font-size:  8.1vw;
      width:67vw;
      margin-top:-2rem;
      padding-right: 1rem;
      line-height: 48px;
      margin-left: 1.5rem;
      }
      
      .subform{
      line-height: 25px;
      width:90vw;
      font-size:3.7vw;
      line-height: 25px;
      top: -5rem;
      margin-left: .5rem;
      margin-top: 8rem;
      }
      
    .section_form{
      height: 105vh;
     
      }
      .auth-form{ 
        margin-top: -1rem;
      position: relative;
      width: 90vw;
      margin-left: .2rem;
      }
  
      .form1{width: 0;}
  
  
    .title,.tit1{ position: absolute;}

    .coche{
      top:-21px;
      margin-left: 0px;
    }

    .carriot{
      width: 600px;
    height: 106px;
    }

  /* ---------------------------------------------- */

    .custitle, .title, .custitle1 {
       font-size:8.2vw; }
  
       .conatiner-media{
        height: 100vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-direction: column-reverse;
      }
  
      .conatiner-media{
          display:grid;
          grid-auto-rows: 15rem;
          grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
          margin: 0 .1em 0 .1em ;
           margin-top: 9rem;
        }
  
        .titlemedia{
          bottom: 11em;
          height:41em ;
          display: flex;
          justify-content: center;
        }
      
        
    .sect{
      display:grid;
      grid-auto-rows: 18rem;
      grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
      margin: 0 1em 0 1em ;
      top: 2rem;
      height: 20vh;
    }
    
  .custitle1{
  top: 4.5rem;
  font-size:8.5vw;
  margin-left: -2rem;
  }
  
  .tit5{
  font-size: 3.7vw;
  text-align: left;
  margin-top: 11rem;
  width: 92.5vw;
  line-height: 21px;
  margin-left: 1rem;
  }
  
  .titp{
  font-size: 3.7vw;
  font-weight: 600;
  width: 100%;
  }
  
  .section-title-wrap{
    height:30em ;
    width: 24em;
  }
  
    .section-title-wrap,.section-title-wrap-one{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
        margin-top: 2em;
    }
    
    .elote{
      width: 91vw;
      height: 65vh;
      }
      
      .titleapp{
      line-height: 48px;
      top: 1rem;
      height: 16vh;
      width: 100%;
      margin-left: 1.3rem;
      }
      
      .container_slider-first{
      margin-top:-24rem;
      margin-left: .7rem;
      width: 20rem;
      }
  
      .apps{
        width:78vw;
        margin-left: .4rem;
        height: 50vh; 
      }
      .container_slider-section{  margin-right: 5em; }
      
    .solutions{
      margin-top: -2rem;
      margin-left: 1.5rem;
    }
   
    .exh,.ind,.ref,.envio{
      width: 98vw;
      height: 65vh;
      }
      
      .exh{
      margin-top: -11.5rem;
      margin-left: 11.5rem;
      }
  
  .titl,.titE{
        font-size:8.1vw;
        text-align: center;
        line-height: 48px;
        }
  
    .titl{ 
        width: 98.5%;
        margin-top: -18rem;
        margin-right: 9.3rem;
        }
  
  .titlep{
    margin-left: -1rem;
    margin-top: -15.5rem;
  }
  
  .ind{
    margin-top: -9.5rem;
    margin-left: -3.5rem;
    }
  
    .titI,.titf{
      font-size:8.1vw;
      text-align: center;
      width: 97%;
      line-height: 48px;
      }
      
      .titI{
        margin-top: 33.5rem;
        margin-left: 14.5rem;
      }
  
  .titlep,.titlep_first,.titlep_second,.titlep_therd{
    width: 100%;
    text-align: center;
    line-height:21px;
    font-size:3.7vw;
    }
  
    .titlep_first{
      margin-top: 67rem;
        margin-left: 15rem;
    }
    
    .ref{
      margin-left: 11.5rem;
      margin-top: -3.5rem;
      }
  
      .titf{ 
        margin-top: -13.5rem;
        margin-left: 14rem;
        }
            
    .titlep_second{
      margin-top: -42rem;
      margin-left: 14rem;
      }
      
       
      .envio{
        margin-left: -4em;
        margin-top: -12rem;
        }
  
    .title-ind{
    margin-top: -30vh;
    margin-left: -8em;
    }
      
    .title-exp{
      height: 20em;
      width: 90%;
      left: 1.2rem;
      }  
  
        .camera{
        height: 15vh;
        margin-top: 38vh;
        width: 100%;
        margin-left: -8.2em;
        }
        
  
  .titE{ 
  width: 80vw;
    margin-top: 29rem;
    margin-left: 1rem;
    
  }
  
  .titlep_therd{
    margin-top: 68rem;
    margin-left: 1.3rem;
    }
    
    .slider_envio_first{
      margin-top: -3rem;
    }
   /* ----------------------------------------------- */
  
   .color2{
    width: 100vw;
    height: 62vh;
    }

    .how_p{
      margin-right: -0.5rem;
    }
    
   .title-privacity{
    margin-right: 29vw;
    font-size: 8.1vw;
    width: 90vw;
    line-height: 45px;
    margin-left: 7rem;
    }
    .subtitle-terms{
      margin-left: 0rem;
      width: 90vw;
      font-size:3.7vw;
      line-height: 21px;
      }
  
      .candado{
        width:68vw;
        margin-top: -4.5rem;
        margin-left:-7.4rem ;
      }
  
      .title-terms1,.title-terms2{
        font-size: 5.5vw;
        margin-left: -1.5em;
        }
        
        .title-terms1{
        width: 60vw;
        margin-left: 1.5rem;
        }
      
        .subtitle-terms1{
          height: 105vh;
          font-size:3.1vw;
          line-height: 21px;
          text-align: left;
          width: 60vw;
          margin-left: 5.5rem;
          margin-top:1rem;
          }
  
    
          .subtitle-terms2{
            width: 60vw;
            font-size:3.1vw;
            line-height: 21px;
            text-align: left;
            margin-left: 8rem;
      
            }
            
  .terms2{
  margin-top: 14rem;
    height: 80vh;
  }
  
  .section_privacity{ height: 135vh;}
  
  .title-terms{
    width: 60vw;
    margin-left: -3.5rem;
    font-size: 8.1vw;
    line-height: 54px;
    }
  
  .subtitle-conditions{
    margin-left: 4rem;
    margin-top: -1rem;
  width: 90vw;
  font-size:3.7vw;
  line-height: 21px;
  }
  
    .carretilla{
    margin-left: -3rem;
    width: 48vw;
    margin-top: -.5rem;
    }
  
    .title-terms2{ 
      margin-left: 1.5rem;
      width: 62vw;}
  
  .subtitle-conditions_2{ 
    margin-top: -16.5rem;
  }
  
  .color4{
    height: 120vh;
  }
  
    .section-title-wrap-one{
    height:18em ;
    width: 24.5em;
    }
    
    .exi-two{  height: 70vh;}
    
    .exi-two{margin-top: 25em;}
    
    .carousel-slider{
    width: 75vw;
    margin-left: 9vw;
    }
    
    .title-wrap{
    margin-top: -10vh;
    height: 40vh;
    width: 100vw;
    }
    
    .privacity_two{
    margin-top:-22rem;
    height: 55vh;
    }
    
    .privacity_three{
    margin-top:-10rem;
    height: 55vh;
    }
    
    .title_conditions{
    height: 25vh;
    margin-top: -3.5rem;
    }
    /* -------------------------------------- */
    

  }
  
  /* --------------Corregir------------------ */
  /* copiar del query de 390px a 393px */

  @media screen and (min-width:393px) and (max-width:411px){

    .color3{
      width: 100vw;
      height: 55vh;
    }

    .how{
        width: 95%;
       font-size:9vw ;
       margin-top: -12rem;
       margin-left: 1rem;
    }

    .how_p{   
    
        margin-right: 65.5rem;
        font-size: 4.4vw;
        height:2rem ;
        margin-top: -2.6rem;
        width: 82%;
    }

    .team-img img{
        position: absolute;
        width: 60vw;
        margin-left: -12.9rem;
        margin-top: -15rem;    
    }

    .row_card{display: contents;}

     .card-deck_one,.card-deck_two{
      display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr 1fr 1fr;
      }

      .card-deck_three{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr;
      }

      .card-deck_three{ 
        height: 35vh;
        margin-top:-2rem ;
      }

      .card-deck_one{  
          margin-top:6rem ;
          height: 65vh;}

      .card-deck_two{
          
        height: 55vh;  
        margin-top:-15rem ;
      }

      .card_one,.card_two,.card_three,.card_four,
      .card_five,.card_six,.card_seven,.card_eight,
      .card_nine,.card_ten{
        width: 40vw;
        height:20vh;
      }
      .card-img-top{
        margin-bottom: 3rem;
        width:20vw;
        height:10vh;
      }

     .card-title{
        width: 39vw;
        margin-top: -4rem;
        font-size: 4vw;
        font-weight: 700;
     }
   
      .card-text{
        margin-top: -1rem;
        font-size: 2.7vw;
        line-height: 20px;
      }
  
      .card-deck_two > *,
      .card-deck_one > *,
      .card-deck_three > *{      
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 3.1rem;

      }

      .title{ padding-bottom: 1rem;}

 /* ------------------------------------------------ */
    .color{
      height: 75vh;
    }

    .title{
      width: 80vw;
       height: 3rem;
       top: 6rem;
       margin-left: 8.5rem;
      }

      .custitle{       
        margin-bottom: 3.5rem;
        margin-right: 4.9em;
      }
  
      .custom-primary{      
    position:absolute;
    margin-right: 12.3rem;
    bottom:14.5em;
  }
      .tit1{
        
        line-height: 22px;
        font-size: 3.6vw;
        top: 10.5rem;
        text-align: justify;
        margin-left: -2.5rem;
        width: 76vw;
      }
      
      .tit2 {
        width: 85vw;
        margin-top: 7rem;
        font-size: 3.6vw;
        line-height: 25px;
        text-align: left;
        margin-left: -.3rem;
      }

      .cosecha {
        width: 155vw;
      height: 30vh;
      margin-top: -11.4rem;
      margin-left: 14.4rem;
      }
      
  .tit3{
  
    margin-right: 6.6rem;
    font-size: 8.1vw;
    width: 100%;
      margin-top: 1rem;
  }
  .tit4{
    margin-top: 5rem;
    font-size:3.7vw;
    line-height: 21px;
    padding-left: 0.5em;
    width: 25.4em;
    text-align:left;
  }
    
  .canasta{
    margin-top:65rem;
    width: 100vw;
    height: 50vh;
    right: 2.5em;
  }
  
  .vec1,.vec2{
    width: 100vw;
    height: 10vh;
  }
  .vec1 img{  top:-12rem;}
  
  .apple{
    width: 35vw;
    height: 35vh;
     top: -26rem;
  }
  .comp{
    margin-top: -2.6rem;
    margin-left: 2.5rem;
  }
  .containervec{
    margin-top: 2.5rem;
    display: flex;
    gap:4.5rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .uno{ margin-top: -5em; }
  
  .dos{  margin-top: 1em; }


  .vec2 img{
    margin-top:143rem;}
  
  .vec1 img,.vec2 img{
    position: absolute;
    width: 200vw;
    height: 20vh;
    left: 10em;
  }
  
  .titcarrusel{
    margin-top: 138rem;
    font-size: 8.9vw;
    margin-left: -.2rem;
    width: 100vw;
  }

  .corrusel{
    margin-top: 144rem;
  }
.items{
  height: 1rem;
}
 
.items img{
  position: absolute;
  width: 14.9vw;
  height: 6.9vh;
  margin-left: 5rem;
  margin-top: -1.3rem;
}

.fleder,.fleizq{
  width: 43vw;
  padding: 0;

  }
  
.testimonial{ 
  margin-top: -5rem;
  margin-left: -1rem;
  height: 80vh;
}

.pera{
  width: 53vw;
  margin-left: -87rem;
  margin-top: -10rem;
}

.ct-testimonials-slider{bottom:32em;}

  .centered-arrows{
    position: relative;
    bottom: 35rem;
    }
  
  .container_form-title{
  display:grid;
  grid-auto-rows: 15rem;
  grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
  margin: 0 .5em 0 .5em ;
  height: 25em;
  margin-top: -36rem;
  }
  
  .testimonial{
    margin-left: 1.5rem;
  }
  .titleform{
    font-size:  8.1vw;
    width:67vw;
    margin-top:-2rem;
    padding-right: 1rem;
    line-height: 48px;
    margin-left: 1.5rem;
    }
    
    .subform{
    line-height: 25px;
    width:90vw;
    font-size:3.7vw;
    line-height: 25px;
    top: -5rem;
    margin-left: .5rem;
    margin-top: 8rem;
    }
    
  .section_form{
    height: 105vh;
   
    }
    .auth-form{ 
      margin-top: -1rem;
    position: relative;
    width: 90vw;
    margin-left: .2rem;
    }

    .form1{width: 0;}


  .title,.tit1{ position: absolute;}

  .coche{
    top:-21px;
    margin-left: 0px;
  }

  .carriot{
    width: 600px;
  height: 106px;
  }

/* ---------------------------------------------- */

  .custitle, .title, .custitle1 {
     font-size:8.2vw; }

     .conatiner-media{
      height: 100vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-direction: column-reverse;
    }

    .conatiner-media{
        display:grid;
        grid-auto-rows: 15rem;
        grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
        margin: 0 .1em 0 .1em ;
         margin-top: 9rem;
      }

      .titlemedia{
        bottom: 11em;
        height:41em ;
        display: flex;
        justify-content: center;
      }
    
      
  .sect{
    display:grid;
    grid-auto-rows: 18rem;
    grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
    margin: 0 1em 0 1em ;
    top: 2rem;
    height: 20vh;
  }
  
.custitle1{
top: 4.5rem;
font-size:8.5vw;
margin-left: -2rem;
}

.tit5{
font-size: 3.7vw;
text-align: left;
margin-top: 11rem;
width: 92.5vw;
line-height: 21px;
margin-left: 1rem;
}

.titp{
font-size: 3.7vw;
font-weight: 600;
width: 100%;
}

.section-title-wrap{
  height:30em ;
  width: 24em;
}

  .section-title-wrap,.section-title-wrap-one{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
      margin-top: 2em;
  }
  
  .elote{
    width: 91vw;
    height: 65vh;
    }
    
    .titleapp{
    line-height: 48px;
    top: 1rem;
    height: 16vh;
    width: 100%;
    margin-left: 1.3rem;
    }
    
    .container_slider-first{
    margin-top:-24rem;
    margin-left: .7rem;
    width: 20rem;
    }

    .apps{
      width:78vw;
      margin-left: .4rem;
      height: 50vh; 
    }
    .container_slider-section{  margin-right: 5em; }
    
  .solutions{
    margin-top: -2rem;
    margin-left: 1.5rem;
  }
 
  .exh,.ind,.ref,.envio{
    width: 98vw;
    height: 65vh;
    }
    
    .exh{
    margin-top: -11.5rem;
    margin-left: 11.5rem;
    }

.titl,.titE{
      font-size:8.1vw;
      text-align: center;
      line-height: 48px;
      }

  .titl{ 
      width: 98.5%;
      margin-top: -18rem;
      margin-right: 9.3rem;
      }

.titlep{
  margin-left: -1rem;
  margin-top: -15.5rem;
}

.ind{
  margin-top: -9.5rem;
  margin-left: -3.5rem;
  }

  .titI,.titf{
    font-size:8.1vw;
    text-align: center;
    width: 97%;
    line-height: 48px;
    }
    
    .titI{
      margin-top: 33.5rem;
      margin-left: 14.5rem;
    }

.titlep,.titlep_first,.titlep_second,.titlep_therd{
  width: 100%;
  text-align: center;
  line-height:21px;
  font-size:3.7vw;
  }

  .titlep_first{
    margin-top: 67rem;
      margin-left: 15rem;
  }
  
  .ref{
    margin-left: 11.5rem;
    margin-top: -3.5rem;
    }

    .titf{ 
      margin-top: -13.5rem;
      margin-left: 14rem;
      }
          
  .titlep_second{
    margin-top: -42rem;
    margin-left: 14rem;
    }
    
     
    .envio{
      margin-left: -4em;
      margin-top: -12rem;
      }

  .title-ind{
  margin-top: -30vh;
  margin-left: -8em;
  }
    
  .title-exp{
    height: 20em;
    width: 90%;
    left: 1.2rem;
    }  

      .camera{
      height: 15vh;
      margin-top: 38vh;
      width: 100%;
      margin-left: -8.2em;
      }
      

.titE{ 
width: 80vw;
  margin-top: 29rem;
  margin-left: 1rem;
  
}

.titlep_therd{
  margin-top: 68rem;
  margin-left: 1.3rem;
  }
  
  .slider_envio_first{
    margin-top: -3rem;
  }
 /* ----------------------------------------------- */

 .color2{
  width: 100vw;
  height: 62vh;
  }

  .how_p{
    margin-right: -0.5rem;
  }
  
 .title-privacity{
  margin-right: 29vw;
  font-size: 8.1vw;
  width: 90vw;
  line-height: 45px;
  margin-left: 7rem;
  }
  .subtitle-terms{
    margin-left: 0rem;
    width: 90vw;
    font-size:3.7vw;
    line-height: 21px;
    }

    .candado{
      width:68vw;
      margin-top: -4.5rem;
      margin-left:-7.4rem ;
    }

    .title-terms1,.title-terms2{
      font-size: 5.5vw;
      margin-left: -1.5em;
      }
      
      .title-terms1{
      width: 60vw;
      margin-left: 1.5rem;
      }
    
      .subtitle-terms1{
        height: 105vh;
        font-size:3.1vw;
        line-height: 21px;
        text-align: left;
        width: 60vw;
        margin-left: 5.5rem;
        margin-top:1rem;
        }

  
        .subtitle-terms2{
          width: 60vw;
          font-size:3.1vw;
          line-height: 21px;
          text-align: left;
          margin-left: 8rem;
    
          }
          
.terms2{
margin-top: 14rem;
  height: 80vh;
}

.section_privacity{ height: 135vh;}

.title-terms{
  width: 60vw;
  margin-left: -3.5rem;
  font-size: 8.1vw;
  line-height: 54px;
  }

.subtitle-conditions{
  margin-left: 4rem;
  margin-top: -1rem;
width: 90vw;
font-size:3.7vw;
line-height: 21px;
}

  .carretilla{
  margin-left: -3rem;
  width: 48vw;
  margin-top: -.5rem;
  }

  .title-terms2{ 
    margin-left: 1.5rem;
    width: 62vw;}

.subtitle-conditions_2{ 
  margin-top: -16.5rem;
}

.color4{
  height: 120vh;
}

  .section-title-wrap-one{
  height:18em ;
  width: 24.5em;
  }
  
  .exi-two{  height: 70vh;}
  
  .exi-two{margin-top: 25em;}
  
  .carousel-slider{
  width: 75vw;
  margin-left: 9vw;
  }
  
  .title-wrap{
  margin-top: -10vh;
  height: 40vh;
  width: 100vw;
  }
  
  .privacity_two{
  margin-top:-22rem;
  height: 55vh;
  }
  
  .privacity_three{
  margin-top:-10rem;
  height: 55vh;
  }
  
  .title_conditions{
  height: 25vh;
  margin-top: -3.5rem;
  }
  /* -------------------------------------- */
  

   

  }
  
  @media screen and (min-width:412px) and (max-width:413px){
    
    .color3{
      width: 100vw;
      height: 55vh;
    }

    .how{
        width: 95%;
       font-size:9vw ;
       margin-top: -12rem;
       margin-left: 1rem;
    }

    .how_p{   
    
        margin-right: 65.5rem;
        font-size: 4.4vw;
        height:2rem ;
        margin-top: -2.6rem;
        width: 82%;
    }

    .team-img img{
        position: absolute;
        width: 60vw;
        margin-left: -12.9rem;
        margin-top: -15rem;    
    }

    .row_card{display: contents;}

     .card-deck_one,.card-deck_two{
      display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr 1fr 1fr;
      }

      .card-deck_three{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr;
      }

      .card-deck_three{ 
        height: 35vh;
        margin-top:-2rem ;
      }

      .card-deck_one{  
          margin-top:6rem ;
          height: 65vh;}

      .card-deck_two{
          
        height: 55vh;  
        margin-top:-15rem ;
      }

      .card_one,.card_two,.card_three,.card_four,
      .card_five,.card_six,.card_seven,.card_eight,
      .card_nine,.card_ten{
        width: 40vw;
        height:20vh;
      }
      .card-img-top{
        margin-bottom: 3rem;
        width:20vw;
        height:10vh;
      }

     .card-title{
        width: 39vw;
        margin-top: -4rem;
        font-size: 4vw;
        font-weight: 700;
     }
   
      .card-text{
        margin-top: -1rem;
        font-size: 2.7vw;
        line-height: 20px;
      }
  
      .card-deck_two > *,
      .card-deck_one > *,
      .card-deck_three > *{      
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 3.1rem;

      }

      .title{ padding-bottom: 1rem;}

 /* ------------------------------------------------ */
    .color{
      height: 75vh;
    }

    .title{
      width: 80vw;
       height: 3rem;
       top: 6rem;
       margin-left: 9.5rem;
      }

      .custitle{       
        margin-bottom: 4.5rem;
        margin-right: 4.9em;
      }
  
      .custom-primary{      
    position:absolute;
    margin-right: 12.5rem;
    bottom:14.5em;
  }
      .tit1{
        
        line-height: 22px;
        font-size: 3.6vw;
        top: 10.5rem;
        text-align: justify;
        margin-left: -2rem;
        width: 76vw;
      }
      
      .tit2 {
        width: 85vw;
        margin-top: 7rem;
        font-size: 3.6vw;
        line-height: 25px;
        text-align: left;
        margin-left: .5rem;
      }

      .cosecha {
        width: 155vw;
      height: 30vh;
      margin-top: -11.4rem;
      margin-left: 14.4rem;
      }
      
  .tit3{
  
    margin-right: 6.6rem;
    font-size: 8.1vw;
    width: 100%;
      margin-top: 1rem;
  }
  .tit4{
    margin-top: 5rem;
    font-size:3.7vw;
    line-height: 21px;
    padding-left: 0.5em;
    width: 25.4em;
    text-align:left;
  }
    
  .canasta{
    margin-top:65rem;
    width: 100vw;
    height: 50vh;
    right: 2.5em;
  }
  
  .vec1,.vec2{
    width: 100vw;
    height: 10vh;
  }
  .vec1 img{  top:-12rem;}
  
  .apple{
    width: 35vw;
    height: 35vh;
     top: -26rem;
  }
  .comp{
    margin-top: -2.6rem;
    margin-left: 2.5rem;
  }
  .containervec{
    margin-top: 2.5rem;
    display: flex;
    gap:4.5rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .uno{ margin-top: -4em; }
  
  .dos{  margin-top: .5em; }


  .vec2 img{
    margin-top:150rem;}
  
  .vec1 img,.vec2 img{
    position: absolute;
    width: 200vw;
    height: 20vh;
    left: 10em;
  }
  
  .titcarrusel{
    margin-top: 140rem;
    font-size: 8.9vw;
    margin-left: -.2rem;
    width: 100vw;
  }

  .corrusel{
    margin-top: 148rem;
  }
.items{
  height: 1rem;
}
 
.items img{
  position: absolute;
  width: 14.9vw;
  height: 6.9vh;
  margin-left: 5.5rem;
  margin-top: -1.7rem;
}

.fleder,.fleizq{
  width: 43vw;
  padding: 0;

  }
  
.testimonial{ 
  margin-top: -5rem;
  margin-right: -1.5rem;
 
}

.pera{
  width: 53vw;
  margin-left: -86rem;
  margin-top: -10rem;
}

.ct-testimonials-slider{bottom:32em;}

  .centered-arrows{
    position: relative;
    bottom: 32rem;
    }
  
  .container_form-title{
  display:grid;
  grid-auto-rows: 15rem;
  grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
  margin: 0 .5em 0 .5em ;
  height: 25em;
  margin-top: -34rem;
  }
  
  .testimonial{
    margin-left: 1.5rem;
  }

  .titleform{
    font-size:  8.1vw;
    width:67vw;
    margin-top:-2rem;
    padding-right: .3rem;
    line-height: 48px;
    margin-left: 1.5rem;
    }
    
    .subform{
    line-height: 25px;
    width:90vw;
    font-size:3.7vw;
    line-height: 25px;
    top: -5rem;
    margin-left: 1.7rem;
    margin-top: 8rem;
    }
    
  .section_form{
    height: 105vh;
   
    }
    .auth-form{ 
      margin-top: -1rem;
    position: relative;
    width: 90vw;
    margin-left: .2rem;
    }

    .form1{width: 0;}


  .title,.tit1{ position: absolute;}

  .coche{
    top:-21px;
    margin-left: 0px;
  }

  .carriot{
    width: 600px;
  height: 106px;
  }

/* ---------------------------------------------- */

  .custitle, .title, .custitle1 {
     font-size:8.2vw; }

     .conatiner-media{
      height: 100vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-direction: column-reverse;
    }

    .conatiner-media{
        display:grid;
        grid-auto-rows: 15rem;
        grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
        margin: 0 .1em 0 .1em ;
         margin-top: 9rem;
      }

      .titlemedia{
        bottom: 11em;
        height:41em ;
        display: flex;
        justify-content: center;
      }
    
      
  .sect{
    display:grid;
    grid-auto-rows: 18rem;
    grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
    margin: 0 1em 0 1em ;
    top: 2rem;
    height: 20vh;
  }
  
.custitle1{
top: 4.5rem;
font-size:8.5vw;
margin-left: -1rem;
}

.tit5{
font-size: 3.7vw;
text-align: left;
margin-top: 11rem;
width: 92.5vw;
line-height: 21px;
margin-left: 2.5rem;
}

.titp{
font-size: 3.7vw;
font-weight: 600;
width: 100%;
}

.section-title-wrap{
  height:30em ;
  width: 24em;
}

  .section-title-wrap,.section-title-wrap-one{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
      margin-top: 2em;
  }
  
  .elote{
    width: 91vw;
    height: 65vh;
    }
    
    .titleapp{
    line-height: 48px;
    top: 1rem;
    height: 16vh;
    width: 100%;
    margin-left: 2.5rem;
    }
    
    .container_slider-first{
    margin-top:-24rem;
    margin-left: .5rem;
    width: 20rem;
    }

    .apps{
      width:79vw;
      margin-right: -1rem;
      height: 50vh; 
    }
    .container_slider-section{  margin-right: 5em; }
    
  .solutions{
    margin-top: -2rem;
    margin-left: 1.5rem;
  }
 
  .exh,.ind,.ref,.envio{
    width: 98vw;
    height: 65vh;
    }
    
    .exh{
    margin-top: -11.5rem;
    margin-left: 11.5rem;
    }

.titl,.titE{
      font-size:8.1vw;
      text-align: center;
      line-height: 48px;
      }

  .titl{ 
      width: 98.5%;
      margin-top: -18rem;
      margin-right: 9.3rem;
      }

.titlep{
  margin-left: .2rem;
  margin-top: -15.5rem;
}

.ind{
  margin-top: -8rem;
  margin-left: -3.5rem;
  }

  .titI,.titf{
    font-size:8.1vw;
    text-align: center;
    width: 97%;
    line-height: 48px;
    }
    
    .titI{
      margin-top: 38.5rem;
      margin-left: 15.5rem;
    }

.titlep,.titlep_first,.titlep_second,.titlep_therd{
  width: 100%;
  text-align: center;
  line-height:21px;
  font-size:3.7vw;
  }

  .titlep_first{
    margin-top: 77.5rem;
      margin-left: 16.5rem;
  }
  
  .ref{
    margin-left: 11.5rem;
    margin-top: -3.5rem;
    }

    .titf{ 
      margin-top: -13.5rem;
      margin-left: 14rem;
      }
          
  .titlep_second{
    margin-top: -45rem;
    margin-left: 14rem;
    }
    
     
    .envio{
      margin-left: -4em;
      margin-top: -12rem;
      }

  .title-ind{
  margin-top: -35vh;
  margin-left: -8em;
  }
    
  .title-exp{
    height: 20em;
    width: 90%;
    left: 1.2rem;
    }  

      .camera{
      height: 15vh;
      margin-top: 38vh;
      width: 100%;
      margin-left: -8.2em;
      }
      

.titE{ 
width: 80vw;
  margin-top: 32rem;
  margin-left: 2rem;
  
}

.titlep_therd{
  margin-top: 73rem;
  margin-left: 2rem;
  }
  
  .slider_envio_first{
    margin-top: -3rem;
  }
 /* ----------------------------------------------- */

 .color2{
  width: 100vw;
  height: 62vh;
  }

  .how_p{
    margin-right: -0.5rem;
  }
  
 .title-privacity{
  margin-right: 29vw;
  font-size: 8.1vw;
  width: 90vw;
  line-height: 45px;
  margin-left: 7rem;
  }
  .subtitle-terms{
    margin-left: 0rem;
    width: 90vw;
    font-size:3.7vw;
    line-height: 21px;
    }

    .candado{
      width:68vw;
      margin-top: -4.5rem;
      margin-left:-7.4rem ;
    }

    .title-terms1,.title-terms2{
      font-size: 5.5vw;
      margin-left: -1.5em;
      }
      
      .title-terms1{
      width: 60vw;
      margin-left: 1.5rem;
      }
    
      .subtitle-terms1{
        height: 105vh;
        font-size:3.1vw;
        line-height: 21px;
        text-align: left;
        width: 60vw;
        margin-left: 5.5rem;
        margin-top:1rem;
        }

  
        .subtitle-terms2{
          width: 60vw;
          font-size:3.1vw;
          line-height: 21px;
          text-align: left;
          margin-left: 8rem;
    
          }
          
.terms2{
margin-top: 14rem;
  height: 80vh;
}

.section_privacity{ height: 135vh;}

.title-terms{
  width: 60vw;
  margin-left: -3.5rem;
  font-size: 8.1vw;
  line-height: 54px;
  }

.subtitle-conditions{
  margin-left: 4rem;
  margin-top: -1rem;
width: 90vw;
font-size:3.7vw;
line-height: 21px;
}

  .carretilla{
  margin-left: -3rem;
  width: 48vw;
  margin-top: -.5rem;
  }

  .title-terms2{ 
    margin-left: 1.5rem;
    width: 62vw;}

.subtitle-conditions_2{ 
  margin-top: -16.5rem;
}

.color4{
  height: 120vh;
}

  .section-title-wrap-one{
  height:18em ;
  width: 24.5em;
  }
  
  .exi-two{  height: 70vh;}
  
  .exi-two{margin-top: 25em;}
  
  .carousel-slider{
  width: 75vw;
  margin-left: 9vw;
  }
  
  .title-wrap{
  margin-top: -10vh;
  height: 40vh;
  width: 100vw;
  }
  
  .privacity_two{
  margin-top:-22rem;
  height: 55vh;
  }
  
  .privacity_three{
  margin-top:-10rem;
  height: 55vh;
  }
  
  .title_conditions{
  height: 25vh;
  margin-top: -3.5rem;
  }
  /* -------------------------------------- */
  



  }

  @media screen and (min-width:414px) and (max-width:427px){
    
    .color3{
      width: 100vw;
      height: 55vh;
    }

    .how{
        width: 100%;
       font-size:9vw ;
       margin-top: -12rem;
       margin-left: 2.5rem;
    }

    .how_p{   
    
        margin-right: -2.5rem;
        font-size: 4.4vw;
        height:2rem ;
        margin-top: -1.9rem;
        width: 100%;
    }

    .team-img img{
        position: absolute;
        width: 55vw;
        margin-left: -11.5rem;
        margin-top: -13.5rem;    
    }

    .row_card{display: contents;}

     .card-deck_one,.card-deck_two{
      display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr 1fr 1fr;
      }

      .card-deck_three{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr;
      }

      .card-deck_three{ 
        height: 35vh;
        margin-top:-2rem ;
      }

      .card-deck_one{  
          margin-top:6rem ;
          height: 65vh;}

      .card-deck_two{
          
        height: 55vh;  
        margin-top:-15rem ;
      }

      .card_one,.card_two,.card_three,.card_four,
      .card_five,.card_six,.card_seven,.card_eight,
      .card_nine,.card_ten{
        width: 40vw;
        height:20vh;
      }
      .card-img-top{
        margin-bottom: 3rem;
        width:20vw;
        height:10vh;
      }

     .card-title{
        width: 39vw;
        margin-top: -4rem;
        font-size: 4vw;
        font-weight: 700;
     }
   
      .card-text{
        margin-top: -1rem;
        font-size: 2.7vw;
        line-height: 20px;
      }
  
      .card-deck_two > *,
      .card-deck_one > *,
      .card-deck_three > *{      
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 3.1rem;

      }

      .title{ padding-bottom: 1rem;}
    /* --------------------------------------------------------------
     */
    .color{
      height: 75vh;
    }

    .title{
      width: 80vw;
       height: 3rem;
       top: 6rem;
       margin-left: 9.5rem;
      }

      .custitle{       
        margin-bottom: 4.5rem;
        margin-right: 4.9em;
      }
  
      .custom-primary{      
    position:absolute;
    margin-right: 12.5rem;
    bottom:14.5em;
  }
      .tit1{
        
        line-height: 22px;
        font-size: 3.6vw;
        top: 10.5rem;
        text-align: justify;
        margin-left: -2rem;
        width: 76vw;
      }
      
      .tit2 {
        width: 85vw;
        margin-top: 7rem;
        font-size: 3.6vw;
        line-height: 25px;
        text-align: left;
        margin-left: .5rem;
      }

      .cosecha {
        width: 155vw;
      height: 30vh;
      margin-top: -11.4rem;
      margin-left: 14.4rem;
      }
      
  .tit3{
  
    margin-right: 6.6rem;
    font-size: 8.1vw;
    width: 100%;
      margin-top: 1rem;
  }
  .tit4{
    margin-top: 5rem;
    font-size:3.7vw;
    line-height: 21px;
    padding-left: 0.5em;
    width: 25.4em;
    text-align:left;
  }
    
  .canasta{
    margin-top:65rem;
    width: 100vw;
    height: 50vh;
    right: 2.5em;
  }
  
  .vec1,.vec2{
    width: 100vw;
    height: 10vh;
  }
  .vec1 img{  top:-12rem;}
  
  .apple{
    width: 35vw;
    height: 35vh;
     top: -26rem;
  }
  .comp{
    margin-top: -2.6rem;
    margin-left: 2.5rem;
  }
  .containervec{
    margin-top: 2.5rem;
    display: flex;
    gap:4.5rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .uno{ margin-top: -4em; }
  
  .dos{  margin-top: .5em; }

  .vec2 img{
    margin-top:150rem;}
  
  .vec1 img,.vec2 img{
    position: absolute;
    width: 200vw;
    height: 20vh;
    left: 10em;
  }
  
  .titcarrusel{
    margin-top: 140rem;
    font-size: 8.9vw;
    margin-left: -.2rem;
    width: 100vw;
  }

  .corrusel{
    margin-top: 148rem;
  }
.items{
  height: 1rem;
}
 
.items img{
  position: absolute;
  width: 14.9vw;
  height: 6.9vh;
  margin-left: 5.5rem;
  margin-top: -1.7rem;
}

.fleder,.fleizq{
  width: 43vw;
  padding: 0;

  }
  
.testimonial{ 
  margin-top: -5rem;
  margin-right: -1.5rem;
 
}

.pera{
  width: 53vw;
  margin-left: -86rem;
  margin-top: -10rem;
}

.ct-testimonials-slider{bottom:32em;}

  .centered-arrows{
    position: relative;
    bottom: 32rem;
    }
  
  .container_form-title{
  display:grid;
  grid-auto-rows: 15rem;
  grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
  margin: 0 .5em 0 .5em ;
  height: 25em;
  margin-top: -34rem;
  }
  
  .testimonial{
    margin-left: 1.5rem;
  }

  .titleform{
    font-size:  8.1vw;
    width:67vw;
    margin-top:-2rem;
    padding-right: .3rem;
    line-height: 48px;
    margin-left: 1.5rem;
    }
    
    .subform{
    line-height: 25px;
    width:90vw;
    font-size:3.7vw;
    line-height: 25px;
    top: -5rem;
    margin-left: 1.7rem;
    margin-top: 8rem;
    }
    
  .section_form{
    height: 105vh;
   
    }
    .auth-form{ 
      margin-top: -1rem;
    position: relative;
    width: 90vw;
    margin-left: .2rem;
    }

    .form1{width: 0;}


  .title,.tit1{ position: absolute;}

  .coche{
    top:-21px;
    margin-left: 0px;
  }

  .carriot{
    width: 600px;
  height: 106px;
  }

/* ---------------------------------------------- */

  .custitle, .title, .custitle1 {
     font-size:8.2vw; }

     .conatiner-media{
      height: 100vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-direction: column-reverse;
    }

    .conatiner-media{
        display:grid;
        grid-auto-rows: 15rem;
        grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
        margin: 0 .1em 0 .1em ;
         margin-top: 9rem;
      }

      .titlemedia{
        bottom: 11em;
        height:41em ;
        display: flex;
        justify-content: center;
      }
    
      
  .sect{
    display:grid;
    grid-auto-rows: 18rem;
    grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
    margin: 0 1em 0 1em ;
    top: 2rem;
    height: 20vh;
  }
  
.custitle1{
top: 4.5rem;
font-size:8.5vw;
margin-left: -.5rem;
}

.tit5{
font-size: 3.7vw;
text-align: left;
margin-top: 10rem;
width: 92.5vw;
line-height: 21px;
margin-left: 3rem;
}

.titp{
font-size: 3.7vw;
font-weight: 600;
width: 100%;
}

.section-title-wrap{
  height:30em ;
  width: 24em;
}

  .section-title-wrap,.section-title-wrap-one{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
      margin-top: 2em;
  }
  
  .elote{
    margin-top: -2rem;
    width: 91vw;
    height: 65vh;
    }
    
    .titleapp{
    line-height: 48px;
    top: -3rem;
    height: 16vh;
    width: 100%;
    margin-left: 2.5rem;
    }
    
    .container_slider-first{
    margin-top:-27rem;
    margin-left: .5rem;
    width: 20rem;
    }

    .apps{
      width:79vw;
      margin-right: -1rem;
      height: 50vh; 
    }
    .container_slider-section{  margin-right: 5em; }
    
  .solutions{
    margin-top: -2rem;
    margin-left: 2rem;
  }
 
  .exh,.ind,.ref,.envio{
    width: 98vw;
    height: 65vh;
    }
    
    .exh{
    margin-top: -10.5rem;
    margin-left: 11.5rem;
    }

.titl,.titE{
      font-size:8.1vw;
      text-align: center;
      line-height: 48px;
      }

  .titl{ 
      width: 98.5%;
      margin-top: -18rem;
      margin-right: 9.3rem;
      }

.titlep{
  margin-left: .2rem;
  margin-top: -15.5rem;
}

.ind{
  margin-top: -7rem;
  margin-left: -3.5rem;
  }

  .titI,.titf{
    font-size:8.1vw;
    text-align: center;
    width: 97%;
    line-height: 48px;
    }
    
    .titI{
      margin-top: 42.5rem;
      margin-left: 16.5rem;
    }

.titlep,.titlep_first,.titlep_second,.titlep_therd{
  width: 100%;
  text-align: center;
  line-height:21px;
  font-size:3.7vw;
  }

  .titlep_first{
    margin-top:87.5rem;
      margin-left: 17rem;
  }
  
  .ref{
    margin-left: 11.5rem;
    margin-top: -1.5rem;
    }

    .titf{ 
      margin-top: -13rem;
      margin-left: 14rem;
      }
          
  .titlep_second{
    margin-top: -43.5rem;
    margin-left: 14rem;
    }
    
     
    .envio{
      margin-left: -4em;
      margin-top: -11.5rem;
      }

  .title-ind{
  margin-top: -35vh;
  margin-left: -8em;
  }
    
  .title-exp{
    height: 20em;
    width: 90%;
    left: 1.2rem;
    }  

      .camera{
      height: 15vh;
      margin-top: 38vh;
      width: 100%;
      margin-left: -8.2em;
      }
      
.titE{ 
width: 80vw;
  margin-top: 35rem;
  margin-left: 2.7rem;
  
}

.titlep_therd{
  margin-top: 81rem;
  margin-left: 3rem;
  }
  
  .slider_envio_first{
    margin-top: 3rem;
  }
  /* -------------------------------------------------------
   */

   
 .color2{
  width: 100vw;
  height: 62vh;
  }

  .how_p{
    margin-right: -0.5rem;
  }
  
 .title-privacity{
  margin-right: 29vw;
  font-size: 8.1vw;
  width: 90vw;
  line-height: 45px;
  margin-left: 7rem;
  }
  .subtitle-terms{
    margin-left: 0rem;
    width: 90vw;
    font-size:3.7vw;
    line-height: 21px;
    }

    .candado{
      width:68vw;
      margin-top: -4.5rem;
      margin-left:-7.4rem ;
    }

    .title-terms1,.title-terms2{
      font-size: 5.5vw;
      margin-left: -1.5em;
      }
      
      .title-terms1{
      width: 60vw;
      margin-left: 1.5rem;
      }
    
      .subtitle-terms1{
        height: 105vh;
        font-size:3.1vw;
        line-height: 21px;
        text-align: left;
        width: 60vw;
        margin-left: 5.5rem;
        margin-top:1rem;
        }

  
        .subtitle-terms2{
          width: 60vw;
          font-size:3.1vw;
          line-height: 21px;
          text-align: left;
          margin-left: 8rem;
    
          }
          
.terms2{
margin-top: 11rem;
  height: 80vh;
}

.section_privacity{ height: 130vh;}

.title-terms{
  width: 60vw;
  margin-left: -2.5rem;
  font-size: 8.1vw;
  line-height: 54px;
  }

.subtitle-conditions{
  margin-left: 5.5rem;
  margin-top: -1rem;
width: 90vw;
font-size:3.7vw;
line-height: 21px;
}

  .carretilla{
  margin-left: -3rem;
  width: 53vw;
  margin-top: -.5rem;
  }

  .title-terms2{ 
    margin-left: 1.5rem;
    width: 62vw;}

.subtitle-conditions_2{ 
  margin-top: -16.5rem;
}

.color4{
  height: 120vh;
}

  .section-title-wrap-one{
  height:18em ;
  width: 24.5em;
  }
  
  .exi-two{  height: 70vh;}
  
  .exi-two{margin-top: 25em;}
  
  .carousel-slider{
  width: 75vw;
  margin-left: 9vw;
  }
  
  .title-wrap{
  margin-top: -10vh;
  height: 40vh;
  width: 100vw;
  }
  
  .privacity_two{
  margin-top:-22rem;
  height: 55vh;
  }
  
  .privacity_three{
  margin-top:-10rem;
  height: 55vh;
  }
  
  .title_conditions{
  height: 25vh;
  margin-top: -3.5rem;
  }
  }

  @media screen and (min-width:428px) and (max-width:432px){
      
    .color3{
      width: 100vw;
      height: 55vh;
    }

    .how{
        width: 100%;
       font-size:9vw ;
       margin-top: -10rem;
       margin-left: 2.5rem;
    }

    .how_p{   
    
        margin-right: -2.5rem;
        font-size: 4.4vw;
        height:2rem ;
        margin-top: -1.9rem;
        width: 100%;
    }

    .team-img img{
        position: absolute;
        width: 52vw;
        margin-left: -10.5rem;
        margin-top: -13rem;    
    }

    .row_card{display: contents;}

     .card-deck_one,.card-deck_two{
      display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr 1fr 1fr;
      }

      .card-deck_three{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr;
      }

      .card-deck_three{ 
        height: 35vh;
        margin-top:-2rem ;
      }

      .card-deck_one{  
          margin-top:6rem ;
          height: 65vh;}

      .card-deck_two{
          
        height: 55vh;  
        margin-top:-15rem ;
      }

      .card_one,.card_two,.card_three,.card_four,
      .card_five,.card_six,.card_seven,.card_eight,
      .card_nine,.card_ten{
        width: 40vw;
        height:20vh;
      }
      .card-img-top{
        margin-bottom: 3rem;
        width:20vw;
        height:10vh;
      }

     .card-title{
        width: 39vw;
        margin-top: -4rem;
        font-size: 4vw;
        font-weight: 700;
     }
   
      .card-text{
        margin-top: -1rem;
        font-size: 2.7vw;
        line-height: 20px;
      }
  
      .card-deck_two > *,
      .card-deck_one > *,
      .card-deck_three > *{      
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 3.1rem;

      }

      .title{ padding-bottom: 1rem;}
    /* --------------------------------------------------------------
     */
    .color{
      height: 75vh;
    }

    .title{
      width: 80vw;
       height: 3rem;
       top: 7rem;
       margin-left: 10.5rem;
      }

      .custitle{       
        margin-bottom: 3.5rem;
        margin-right: 4.5em;
      }
  
      .custom-primary{      
    position:absolute;
    margin-right: 11.5rem;
    bottom:14.5em;
  }
      .tit1{
        
        line-height: 22px;
        font-size: 3.6vw;
        top: 12rem;
        text-align: justify;
        margin-left: -.5rem;
        width: 76vw;
      }
      
      .tit2 {
        width: 85vw;
        margin-top: 11rem;
        font-size: 3.6vw;
        line-height: 25px;
        text-align: left;
        margin-left: 1.9rem;
      }

      .cosecha {
        width: 155vw;
      height: 30vh;
      margin-top: -12rem;
      margin-left: 14rem;
      }
      
  .tit3{
    margin-right: 7.5rem;
    font-size: 8.1vw;
    width: 100%;
      margin-top: 1rem;
  }
  .tit4{
    margin-top: 8.5rem;
    font-size:3.7vw;
    line-height: 21px;
    padding-left: 0.5em;
    width: 25.4em;
    text-align:left;
  }
    
  .canasta{
    margin-top:70rem;
    width: 100vw;
    height: 50vh;
    right: 2.5em;
  }
  
  .vec1,.vec2{
    width: 100vw;
    height: 10vh;
  }
  .vec1 img{  top:-12rem;}
  
  .apple{
    width: 35vw;
    height: 35vh;
     top: -26rem;
  }
  .comp{
    margin-top: -2.6rem;
    margin-left: 2.5rem;
  }
  .containervec{
    margin-top: 2.5rem;
    display: flex;
    gap:4.5rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .uno{ margin-top: -2em; }
  
  .dos{  margin-top: .5em; }


  .vec2 img{margin-top:155rem;}
  
  .vec1 img,.vec2 img{
    position: absolute;
    width: 200vw;
    height: 20vh;
    left: 10em;
  }
  
  .titcarrusel{
    margin-top: 145rem;
    font-size: 8.9vw;
    margin-left: -.2rem;
    width: 100vw;
  }

  .corrusel{
    margin-top: 153rem;
  }
.items{
  height: 1rem;
}
 
.items img{
  position: absolute;
  width: 14.9vw;
  height: 6.9vh;
  margin-left: 6.5rem;
  margin-top: -1.7rem;
}

.fleder,.fleizq{
  width: 43vw;
  padding: 0;

  }
  
.testimonial{ 
  margin-top: -5rem;
  margin-right: -1.5rem;
 
}

.pera{
  width: 53vw;
  margin-left: -86rem;
  margin-top: -10rem;
}

.ct-testimonials-slider{bottom:32em;}

  .centered-arrows{
    position: relative;
    bottom: 32rem;
    }
  
  .container_form-title{
  display:grid;
  grid-auto-rows: 15rem;
  grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
  margin: 0 .5em 0 .5em ;
  height: 25em;
  margin-top: -34rem;
  }
  
  .testimonial{
    margin-left: 1.5rem;
  }

  .titleform{
    font-size:  8.1vw;
    width:67vw;
    margin-top:-.5rem;
    padding-right: .3rem;
    line-height: 48px;
    margin-left: 2.5rem;
    }
    
    .subform{
    line-height: 25px;
    width:90vw;
    font-size:3.7vw;
    line-height: 25px;
    top: -2rem;
    margin-left: 3rem;
    margin-top: 8rem;
    }
    
  .section_form{
    height: 105vh;
   
    }
    .auth-form{ 
      margin-top: -1rem;
    position: relative;
    width: 90vw;
    margin-left: .2rem;
    }

    .form1{width: 0;}


  .title,.tit1{ position: absolute;}

  .coche{
    top:-21px;
    margin-left: 0px;
  }

  .carriot{
    width: 600px;
  height: 106px;
  margin-right: -16rem;
  }

/* ---------------------------------------------- */

  .custitle, .title, .custitle1 {
     font-size:8.2vw; }

     .conatiner-media{
      height: 100vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-direction: column-reverse;
    }

    .conatiner-media{
        display:grid;
        grid-auto-rows: 15rem;
        grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
        margin: 0 .1em 0 .1em ;
         margin-top: 9rem;
      }

      .titlemedia{
        bottom: 11em;
        height:41em ;
        display: flex;
        justify-content: center;
      }
    
      
  .sect{
    display:grid;
    grid-auto-rows: 18rem;
    grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
    margin: 0 1em 0 1em ;
    top: 2rem;
    height: 20vh;
  }
  
.custitle1{
top: 5rem;
font-size:8.5vw;
margin-left: -.5rem;
}

.tit5{
font-size: 3.7vw;
text-align: left;
margin-top: 12rem;
width: 92.5vw;
line-height: 21px;
margin-left: 3rem;
}

.titp{
font-size: 3.7vw;
font-weight: 600;
width: 100%;
}

.section-title-wrap{
  height:30em ;
  width: 24em;
}

  .section-title-wrap,.section-title-wrap-one{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
      margin-top: 2em;
  }
  
  .elote{
    margin-top: -1rem;
    width: 91vw;
    height: 65vh;
    }
    
    .titleapp{
    line-height: 48px;
    top: -3rem;
    height: 16vh;
    width: 100%;
    margin-left: 4rem;
    }
    
    .container_slider-first{
    margin-top:-27rem;
    margin-left: .5rem;
    width: 20rem;
    }

    .apps{
      width:75vw;
      margin-right: -1rem;
      height: 50vh; 
    }
    .container_slider-section{  margin-right: 5em; }
    
  .solutions{
    margin-top: -2rem;
    margin-left: 3rem;
  }
 
  .exh,.ind,.ref,.envio{
    width: 98vw;
    height: 65vh;
    }
    
    .exh{
    margin-top: -11.5rem;
    margin-left: 11.5rem;
    }

.titl,.titE{
      font-size:8.1vw;
      text-align: center;
      line-height: 48px;
      }

  .titl{ 
      width: 98.5%;
      margin-top: -18rem;
      margin-right: 9.3rem;
      }

.titlep{
  margin-left: 1.5rem;
  margin-top: -15.5rem;
}

.ind{
  margin-top: -6.5rem;
  margin-left: -3.5rem;
  }

  .titI,.titf{
    font-size:8.1vw;
    text-align: center;
    width: 97%;
    line-height: 48px;
    }
    
    .titI{
      margin-top: 45rem;
      margin-left: 17.5rem;
    }

.titlep,.titlep_first,.titlep_second,.titlep_therd{
  width: 100%;
  text-align: center;
  line-height:21px;
  font-size:3.7vw;
  }

  .titlep_first{
    margin-top:95.5rem;
      margin-left: 18rem;
      width: 27rem;
  }
  
  .ref{
    margin-left: 11.5rem;
    margin-top: -.3rem;
  
    }

    .titf{ 
      margin-top: -10rem;
      margin-left: 14rem;
      }
          
  .titlep_second{
    margin-top: -35.5rem;
    margin-left: 14rem;
    }
    
     
    .envio{
      margin-left: -4em;
      margin-top: -8rem;
      }

  .title-ind{
  margin-top: -35vh;
  margin-left: -8em;
  }
    
  .title-exp{
    height: 20em;
    width: 90%;
    left: 1.2rem;
    }  

      .camera{
      height: 15vh;
      margin-top: 38vh;
      width: 100%;
      margin-left: -8.2em;
      }
      

.titE{ 
width: 80vw;
  margin-top: 42rem;
  margin-left: 4rem;
  
}

.titlep_therd{
  width: 25rem;
  margin-top: 98rem;
  margin-left: 3.8rem;
  line-height:23px;

  }
  
  .slider_envio_first{
    margin-top: 8rem;
  }
  /* -------------------------------------------------------
   */

   
 .color2{
  width: 100vw;
  height: 62vh;
  }

  .how_p{
    margin-right: -0.5rem;
  }
  
 .title-privacity{
  margin-right: 29vw;
  font-size: 8.1vw;
  width: 90vw;
  line-height: 45px;
  margin-left: 7rem;
  }
  .subtitle-terms{
    margin-left: 0rem;
    width: 90vw;
    font-size:3.7vw;
    line-height: 21px;
    }

    .candado{
      width:68vw;
      margin-top: -4.5rem;
      margin-left:-7.4rem ;
    }

    .title-terms1,.title-terms2{
      font-size: 5.5vw;
      margin-left: -1.5em;
      }
      
      .title-terms1{
      width: 60vw;
      margin-left: 1.5rem;
      }
    
      .subtitle-terms1{
        height: 105vh;
        font-size:3.1vw;
        line-height: 21px;
        text-align: left;
        width: 60vw;
        margin-left: 5.5rem;
        margin-top:1rem;
        }

  
        .subtitle-terms2{
          width: 60vw;
          font-size:3.1vw;
          line-height: 21px;
          text-align: left;
          margin-left: 8rem;
    
          }
          
.terms2{
margin-top: 9rem;
  height: 80vh;
}

.section_privacity{ height: 125vh;}

.title-terms{
  width: 60vw;
  margin-left: -1.5rem;
  font-size: 8.1vw;
  line-height: 54px;
  }

.subtitle-conditions{
  margin-left: 7rem;
  margin-top: -.5rem;
width: 90vw;
font-size:3.7vw;
line-height: 21px;
}

  .carretilla{
  margin-left: -2rem;
  width: 55vw;
  margin-top: -.5rem;
  }

  .title-terms2{ 
    margin-left: 1.5rem;
    width: 62vw;}

.subtitle-conditions_2{ 
  margin-top: -16.5rem;
}

.color4{
  height: 120vh;
}

  .section-title-wrap-one{
  height:18em ;
  width: 24.5em;
  }
  
  .exi-two{  height: 70vh;}
  
  .exi-two{margin-top: 25em;}
  
  .carousel-slider{
  width: 75vw;
  margin-left: 9vw;
  }
  
  .title-wrap{
  margin-top: -10vh;
  height: 40vh;
  width: 100vw;
  }
  
  .privacity_two{
  margin-top:-22rem;
  height: 55vh;
  }
  
  .privacity_three{
  margin-top:-10rem;
  height: 55vh;
  }
  
  .title_conditions{
  height: 25vh;
  margin-top: -3.5rem;
  }

  }
  

  @media screen  and (min-height:736px)  and (max-height:737px){


      .color3{
          width: 100vw;
          height: 55vh;
        }
    
        .how{
            width: 95%;
           font-size:9vw ;
           margin-top: -12rem;
           margin-left: 1rem;
        }
    
        .how_p{   
        
            margin-right: 65.5rem;
            font-size: 4.4vw;
            height:2rem ;
            margin-top: -2.6rem;
            width: 82%;
        }
    
        .team-img img{
            position: absolute;
            width: 48vw;
      margin-left: -11.9rem;
      margin-top: -12rem;
        }
    
        .row_card{display: contents;}
    
         .card-deck_one,.card-deck_two{
          display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr 1fr 1fr 1fr;
          }
    
          .card-deck_three{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr 1fr;
          }
    
          .card-deck_three{ 
            height: 35vh;
            margin-top:-1rem ;
          }
    
          .card-deck_one{  
              margin-top:6rem ;
              height: 65vh;}
    
          .card-deck_two{
              
            height: 55vh;  
            margin-top:-13rem ;
          }
    
          .card_one,.card_two,.card_three,.card_four,
          .card_five,.card_six,.card_seven,.card_eight,
          .card_nine,.card_ten{
            width: 40vw;
            height:20vh;
          }
          .card-img-top{
            margin-bottom: 3rem;
            width:20vw;
            height:10vh;
          }
    
         .card-title{
            width: 39vw;
            margin-top: -4rem;
            font-size: 4vw;
            font-weight: 700;
         }
       
          .card-text{
            margin-top: -1rem;
            font-size: 2.7vw;
            line-height: 20px;
          }
      
          .card-deck_two > *,
          .card-deck_one > *,
          .card-deck_three > *{      
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 3.1rem;
    
          }
    
          .title{ padding-bottom: 1rem;}
  
     /* ------------------------------------------------ */
        .color{
          height: 75vh;
        }
    
        .title{
          width: 80vw;
           height: 3rem;
           top: 6rem;
           margin-left: 8.5rem;
          }
    
          .custitle{       
            margin-bottom: 3.5rem;
            margin-right: 4.9em;
          }
      
          .custom-primary{      
        position:absolute;
        margin-right: 12.3rem;
        bottom:11.5em;
      }
          .tit1{
            
            line-height: 22px;
            font-size: 3.6vw;
            top: 10.5rem;
            text-align: justify;
            margin-left: -2.5rem;
            width: 76vw;
          }
          
          .tit2 {
            width: 85vw;
            margin-top: 7rem;
            font-size: 3.6vw;
            line-height: 25px;
            text-align: left;
            margin-left: -.3rem;
          }
    
          .cosecha {
            width: 155vw;
          height: 30vh;
          margin-top: -10.6rem;
          margin-left: 15rem;
          }
  
          
      .tit3{
      
        margin-right: 6.6rem;
       
        width: 100%;
        top: 2rem;
      }
      .tit4{
       top: 0rem;
        font-size:3.7vw;
        line-height: 21px;
        padding-left: 0.5em;
        width: 25.4em;
        text-align:left;
      }
        
      .canasta{
        top:-4rem;
        width: 100vw;
        height: 50vh;
        right: 2.5em;
      }
  
      .vec1,.vec2{
        width: 100vw;
        height: 10vh;
      }
      .vec1 img{  top:-12rem;}
      
      .apple{
        width: 35vw;
        height: 35vh;
         top: -26rem;
         left: -14px;
      }
      .comp{
        margin-top: -2.6rem;
        margin-left: 2.5rem;
      }
      .containervec{
        margin-top: 2.5rem;
        display: flex;
        gap:4.5rem;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .uno{ margin-top: -5em; }
      
      .dos{  margin-top: 1em; }
    
  
      .vec2 img{
        margin-top:143rem;}
      
      .vec1 img,.vec2 img{
        position: absolute;
        width: 200vw;
        height: 20vh;
        left: 10em;
      }
      
      .titcarrusel{
        margin-top: 138rem;
        font-size: 8.9vw;
        margin-left: -.2rem;
        width: 100vw;
      }
    
      .corrusel{
        margin-top: 144rem;
      }
    .items{
      height: 1rem;
    }
     
    .items img{
      position: absolute;
      width: 14.9vw;
      height: 6.9vh;
      margin-left: 5rem;
      margin-top: -1.3rem;
    }
    
    .fleder,.fleizq{
      width: 43vw;
      padding: 0;
    
      }
      
    .testimonial{ 
      margin-top: -5rem;
      margin-left: -1rem;
      height: 80vh;
    }
    
    .pera{
      width: 53vw;
      margin-left: -87rem;
      margin-top: -10rem;
    }
    
    .ct-testimonials-slider{bottom:32em;}
    
      .centered-arrows{
        position: relative;
        bottom: 35rem;
        }
      
      .container_form-title{
      display:grid;
      grid-auto-rows: 15rem;
      grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
      margin: 0 .5em 0 .5em ;
      height: 25em;
      margin-top: -36rem;
      }
      
      .testimonial{
        margin-left: 1.5rem;
      }
      .titleform{
        font-size:  8.1vw;
        width:67vw;
        margin-top:-2rem;
        padding-right: 1rem;
        line-height: 48px;
        margin-left: 1.5rem;
        }
        
        .subform{
        line-height: 25px;
        width:90vw;
        font-size:3.7vw;
        line-height: 25px;
        top: -5rem;
        margin-left: .5rem;
        margin-top: 8rem;
        }
        
      .section_form{
        height: 105vh;
       
        }
        .auth-form{ 
          margin-top: -1rem;
        position: relative;
        width: 90vw;
        margin-left: .2rem;
        }
    
        .form1{width: 0;}
    
    
      .title,.tit1{ position: absolute;}
  
      .coche{
        top:-21px;
        margin-left: 0px;
      }
  
      .carriot{
        width: 600px;
      height: 106px;
      }
  
    /* ---------------------------------------------- */
  
      .custitle, .title, .custitle1 {
         font-size:8.2vw; }
    
         .conatiner-media{
          height: 100vh;
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-direction: column-reverse;
        }
    
        .conatiner-media{
            display:grid;
            grid-auto-rows: 15rem;
            grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
            margin: 0 .1em 0 .1em ;
             margin-top: 9rem;
          }
    
          .titlemedia{
            bottom: 11em;
            height:41em ;
            display: flex;
            justify-content: center;
          }
        
          
      .sect{
        display:grid;
        grid-auto-rows: 18rem;
        grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
        margin: 0 1em 0 1em ;
        top: 2rem;
        height: 20vh;
      }
      
    .custitle1{
    top: 4.5rem;
    font-size:8.5vw;
    margin-left: -2rem;
    }
    
    .tit5{
    font-size: 3.7vw;
    text-align: left;
    margin-top: 11rem;
    width: 92.5vw;
    line-height: 21px;
    margin-left: 1rem;
    }
    
    .titp{
    font-size: 3.7vw;
    font-weight: 600;
    width: 100%;
    }
    
    .section-title-wrap{
      height:30em ;
      width: 24em;
    }
    
      .section-title-wrap,.section-title-wrap-one{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
          margin-top: 2em;
      }
      
      .elote{
        width: 91vw;
        height: 65vh;
        }
        
        .titleapp{
        line-height: 48px;
        top: 1rem;
        height: 16vh;
        width: 100%;
        margin-left: 1.3rem;
        }
        
        .container_slider-first{
        margin-top:-24rem;
        margin-left: .7rem;
        width: 20rem;
        }
    
        .apps{
          width:78vw;
          margin-left: .4rem;
          height: 50vh; 
        }
        .container_slider-section{  margin-right: 5em; }
        
      .solutions{
        margin-top: -2rem;
        margin-left: 1.5rem;
      }
     
      .exh,.ind,.ref,.envio{
        width: 98vw;
        height: 65vh;
        }
        
        .exh{
        margin-top: -11.5rem;
        margin-left: 11.5rem;
        }
    
    .titl,.titE{
          font-size:8.1vw;
          text-align: center;
          line-height: 48px;
          }
    
      .titl{ 
          width: 98.5%;
          margin-top: -18rem;
          margin-right: 9.3rem;
          }
    
    .titlep{
      margin-left: -1rem;
      margin-top: -15.5rem;
    }
    
    .ind{
      margin-top: -11.5rem;
      margin-left: -3.5rem;
      }
    
      .titI,.titf{
        font-size:8.1vw;
        text-align: center;
        width: 97%;
        line-height: 48px;
        }
        
        .titI{
          margin-top: 25.5rem;
      margin-left: 16rem;
        }
    
    .titlep,.titlep_first,.titlep_second,.titlep_therd{
      width: 100%;
      text-align: center;
      line-height:21px;
      font-size:3.7vw;
      }
    
      .titlep_first{
        margin-top: 50rem;
        margin-left: 16rem;
      }
      
      .ref{
        margin-left: 11.5rem;
        margin-top: -3.5rem;
        }
    
        .titf{ 
          margin-top: -15rem;
          margin-left: 15rem;
      
          }
              
      .titlep_second{
        margin-top: -39rem;
        margin-left: 15rem;
        }
        
         
        .envio{
          margin-left: -4em;
          margin-top: -12rem;
          }
    
      .title-ind{
      margin-top: -30vh;
      margin-left: -8em;
      }
        
      .title-exp{
        height: 20em;
        width: 90%;
        left: 1.2rem;
        }  
    
          .camera{
          height: 15vh;
          margin-top: 38vh;
          width: 100%;
          margin-left: -8.2em;
          }
          
    
    .titE{ 
    width: 80vw;
    margin-top: 24rem;
    margin-left: 1rem;
      
    }
    
    .titlep_therd{
      margin-top: 58rem;
      margin-left: 1.3rem;
      }
      
      .slider_envio_first{
        margin-top: -3rem;
      }
  
  .gallery-imgs{
    height: 60vh;
  }
  
     /* ----------------------------------------------- */
    
     .color2{
      width: 100vw;
      height: 62vh;
      }
  
      .how_p{
        margin-right: -0.5rem;
      }
      
     .title-privacity{
      margin-right: 29vw;
      font-size: 8.1vw;
      width: 90vw;
      line-height: 45px;
      margin-left: 7rem;
      }
      .subtitle-terms{
        margin-left: 0rem;
        width: 90vw;
        font-size:3.7vw;
        line-height: 21px;
        }
    
        .candado{
          width:68vw;
          margin-top: -4.5rem;
          margin-left:-7.4rem ;
        }
    
        .title-terms1,.title-terms2{
          font-size: 5.5vw;
          margin-left: -1.5em;
          }
          
          .title-terms1{
          width: 60vw;
          margin-left: 1.5rem;
          }
        
          .subtitle-terms1{
            height: 120vh;
            font-size: 3.1vw;
            line-height: 21px;
            text-align: left;
            width: 60vw;
            margin-left: 5.6rem;
            margin-top: 1rem;
            }
    
      
            .subtitle-terms2{
              width: 60vw;
              font-size:3.1vw;
              line-height: 21px;
              text-align: left;
              margin-left: 8rem;
        
              }
              
    .terms2{
    margin-top: 14rem;
      height: 80vh;
    }
    
    .section_privacity{ height: 147vh;}
    
    .title-terms{
      width: 60vw;
      margin-left: -3.5rem;
      font-size: 8.1vw;
      line-height: 54px;
      }
    
    .subtitle-conditions{
      margin-left: 4rem;
      margin-top: -1rem;
    width: 90vw;
    font-size:3.7vw;
    line-height: 21px;
    }
    
      .carretilla{
      margin-left: -3rem;
      width: 48vw;
      margin-top: -.5rem;
      }
    
      .title-terms2{ 
        margin-left: 1.5rem;
        width: 62vw;}
    
    .subtitle-conditions_2{ 
      margin-top: -5.5rem;
    }
    
    .color4{
      height: 120vh;
    }
    
      .section-title-wrap-one{
      height:18em ;
      width: 24.5em;
      }
      
      .exi-two{  height: 70vh;}
      
      .exi-two{margin-top: 25em;}
      
      .carousel-slider{
      width: 75vw;
      margin-left: 9vw;
      }
      
      .title-wrap{
      margin-top: -10vh;
      height: 40vh;
      width: 100vw;
      }
      
      .privacity_two{
      margin-top:-22rem;
      height: 55vh;
      }
      
      .privacity_three{
      margin-top:-10rem;
      height: 80vh;
      }
      
      .title_conditions{
      height: 25vh;
      margin-top: -3.5rem;
      }
      /* -------------------------------------- */
      
  
    }

  
  @media screen  and (min-height:674px)  and (max-height:700px){

  .cosecha{
    margin-left: 15.3rem;
  }
    .canasta{
      margin-top:46rem;
    }
    .items img{
      position: absolute;
      width: 14.9vw;
      height: 6.9vh;
      margin-left: 3.5rem;
      margin-top: -1rem;
    }
      .ind{
        margin-top: -12.5rem;
      }
      
      .ref{
      margin-top: -3rem;
      }
      .titI{
        margin-top: 25rem;
      }
      
      .titlep_first{
      margin-top: 45rem;
      
      }
      
      .titf{
        margin-top: -12rem;
      }
      
      .titlep_second{
        margin-top: -36rem;
      }
      .envio{
        margin-top: -13.5rem;
      }
      .titE{
        margin-top: 25rem;
      }

      .titlep_therd{
        margin-top: 53rem;
        margin-left: -.5rem;
      }
      
      .how_p{   
        margin-right: -2.5rem;
        font-size: 4.4vw;
        height:2rem ;
        margin-top: -1.9rem;
        width: 100%;
    }

    .team-img img{
      width: 58vw;
      margin-left: -13rem;
    margin-top: -11rem
    }
    .card-deck_two {
      height: 55vh;
      margin-top: -10rem;
  }
  .privacity_two{
    margin-top: -19rem;
    height: 80vh;
  }
  .section_privacity{
    height: 155vh;
  }
  .subtitle-conditions_2 {
    margin-top: -24rem;
}
.color4{
  height: 155vh;
}
      } 
  
  
  @media screen  and (min-width:485px)  and (max-width:490px){
    
    .color3{
      width: 100vw;
      height: 55vh;
    }

    .how{
        width: 100%;
       font-size:9vw ;
       margin-top: -10rem;
       margin-left: 2.5rem;
    }

    .how_p{   
    
        margin-right: -2.5rem;
        font-size: 4.4vw;
        height:2rem ;
        margin-top: -1.9rem;
        width: 100%;
    }

    .team-img img{
        position: absolute;
        width: 52vw;
        margin-left: -10.5rem;
        margin-top: -13rem;    
    }

    .row_card{display: contents;}

     .card-deck_one,.card-deck_two{
      display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr 1fr 1fr;
      }

      .card-deck_three{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr;
      }

      .card-deck_three{ 
        height: 35vh;
        margin-top:-2rem ;
      }

      .card-deck_one{  
          margin-top:6rem ;
          height: 65vh;}

      .card-deck_two{
          
        height: 55vh;  
        margin-top:-15rem ;
      }

      .card_one,.card_two,.card_three,.card_four,
      .card_five,.card_six,.card_seven,.card_eight,
      .card_nine,.card_ten{
        width: 40vw;
        height:20vh;
      }
      .card-img-top{
        margin-bottom: 3rem;
        width:20vw;
        height:10vh;
      }

     .card-title{
        width: 39vw;
        margin-top: -4rem;
        font-size: 4vw;
        font-weight: 700;
     }
   
      .card-text{
        margin-top: -1rem;
        font-size: 2.7vw;
        line-height: 20px;
      }
  
      .card-deck_two > *,
      .card-deck_one > *,
      .card-deck_three > *{      
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 3.1rem;

      }

      .title{ padding-bottom: 1rem;}
    /* --------------------------------------------------------------
     */
    .color{ height: 80vh;}

    .title{
      width: 80vw;
       height: 3rem;
       top: 8rem;
       margin-left: 14.5rem;
      }

      .custitle{       
        margin-bottom: -1.5rem;
        margin-right: 10rem;
      }
  
      .custom-primary{      
    position:absolute;
    margin-right: 9.4rem;
    bottom:38.5rem;
  }
      .tit1{
        line-height: 22px;
        font-size: 3.6vw;
        top: 13.5rem;
        text-align: justify;
        margin-left: 3.6rem;
        width: 76vw;
      }
      
      .tit2 {
        width: 85vw;
        margin-top: 5rem;
        font-size: 3.6vw;
        line-height: 25px;
        text-align: left;
        margin-left: 6rem;
      }

      .cosecha {
        width: 155vw;
      height: 30vh;
      margin-top: -11.4rem;
      margin-left: 14.4rem;
      }
      
  .tit3{
    margin-right: 12rem;
    font-size: 8.1vw;
    width: 100%;
      margin-top: -1rem;
  }
  .tit4{
    margin-top: -2.5rem;
    font-size:3.7vw;
    line-height: 21px;
    padding-left: 0.5em;
    width: 25.4em;
    text-align:left;
  }
    
  .canasta{
    margin-top:73rem;
    width: 100vw;
    height: 55vh;
    right: 2.5em;
  }
  
  .vec1,.vec2{
    width: 100vw;
    height: 10vh;
  }
  .vec1 img{  top:-12rem;}
  
  .apple{
    width: 35vw;
    height: 35vh;
     top: -26rem;
  }
  .comp{
    margin-top: -2.6rem;
    margin-left: 2.5rem;
  }
  .containervec{
    margin-top: 2.5rem;
    display: flex;
    gap:4.5rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .uno{ margin-top: -2em; }
  
  .dos{  margin-top: .5em; }


  .vec2 img{margin-top:157rem;}
  
  .vec1 img,.vec2 img{
    position: absolute;
    width: 200vw;
    height: 20vh;
    left: 10em;
  }
  
  .titcarrusel{
    margin-top: 145rem;
    font-size: 8.9vw;
    margin-left: -.2rem;
    width: 100vw;
  }

  .corrusel{
    margin-top: 155rem;
  }
.items{
  height: 1rem;
}
 
.items img{
  position: absolute;
  width: 14.9vw;
  height: 7.9vh;
  margin-left: 11rem;
  margin-top: -1.9rem;
}

.fleder,.fleizq{
  width: 40vw;
  padding: 0;

  }
  
.testimonial{ 
  margin-top: -5rem;
  margin-right: -6rem;
 
}

.pera{
  width: 53vw;
  margin-left: -83.5rem;
  margin-top: -7rem;
}

.ct-testimonials-slider{bottom:30em;}

  .centered-arrows{
    position: relative;
    bottom: 26rem;
    }
  
  .container_form-title{
  display:grid;
  grid-auto-rows: 15rem;
  grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
  margin: 0 .5em 0 .5em ;
  height: 25em;
  margin-top: -30rem;
  }
  
  .testimonial{
    margin-left: 1.5rem;
  }

  .titleform{
    font-size:  8.1vw;
    width:67vw;
    margin-top:-.9rem;
    padding-right: .3rem;
    line-height: 48px;
    margin-left: 6.5rem;
    }
    
    .subform{
    line-height: 25px;
    width:90vw;
    font-size:3.7vw;
    line-height: 25px;
    top: -2rem;
    margin-left: 6.5rem;
    margin-top: 10rem;
    }
    
  .section_form{
    height: 105vh;
   
    }
    .auth-form{ 
      margin-top: 1rem;
    position: relative;
    width: 90vw;
    margin-left: .2rem;
    }

    .form1{width: 0;}


  .title,.tit1{ position: absolute;}

  .coche{
    top:-21px;
    margin-left: 0px;
  }

  .carriot{
    width: 680px;
  height: 106px;
  margin-right: -17rem;
  top: 1rem;
  }

/* ---------------------------------------------- */

  .custitle, .title, .custitle1 {
     font-size:8.2vw; }

     .conatiner-media{
      height: 100vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-direction: column-reverse;
    }

    .conatiner-media{
        display:grid;
        grid-auto-rows: 15rem;
        grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
        margin: 0 .1em 0 .1em ;
         margin-top: 9rem;
      }

      .titlemedia{
        bottom: 11em;
        height:41em ;
        display: flex;
        justify-content: center;
      }
    
      
  .sect{
    display:grid;
    grid-auto-rows: 18rem;
    grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem), 1fr));
    margin: 0 1em 0 1em ;
    top: 2rem;
    height: 20vh;
  }
  
.custitle1{
top: 8rem;
font-size:8.5vw;
margin-left: 3.5rem;
}

.tit5{
font-size: 3.7vw;
text-align: left;
margin-top: 10rem;
width: 92.5vw;
line-height: 21px;
margin-left: 7.5rem;
}

.titp{
  margin-left: 1rem;
font-size: 3.7vw;
font-weight: 600;
width: 100%;
}

.section-title-wrap{
  height:30em ;
  width: 24em;
}

  .section-title-wrap,.section-title-wrap-one{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
      margin-top: 8em;
  }
  
  .elote{
    margin-top: 1rem;
    width: 91vw;
    height: 65vh;
    }
    
    .titleapp{
    line-height: 48px;
    top: 2rem;
    height: 16vh;
    width: 100%;
    margin-left: 7rem;
    }
    
    .container_slider-first{
    margin-top:-27rem;
    margin-left: 1rem;
    width: 24.5rem;
    }

    .apps{
      width:80.5vw;
      margin-right: -.5rem;
      height: 53vh; 
    }
    .container_slider-section{  margin-right: 5em; }
    
  .solutions{
    margin-top: -3rem;
    margin-left: 5rem;
  }
 
  .exh,.ind,.ref,.envio{
    width: 98vw;
    height: 65vh;
    }
    
    .exh{
    margin-top: -13rem;
    margin-left: 11.5rem;
    }

.titl,.titE{
      font-size:8.1vw;
      text-align: center;
      line-height: 48px;
      }

  .titl{ 
      width: 98.5%;
      margin-top: -18rem;
      margin-right: 10rem;
      }

.titlep{
  margin-left: 4.5rem;
  margin-top: -15.5rem;
}

.ind{
  margin-top: -9.5rem;
  margin-left: -3.5rem;
  }

  .titI,.titf{
    font-size:8.1vw;
    text-align: center;
    width: 97%;
    line-height: 48px;
    }
    
    .titI{
      margin-top: 42rem;
      margin-left: 21rem;
    }

.titlep,.titlep_first,.titlep_second,.titlep_therd{
  width: 100%;
  text-align: center;
  line-height:21px;
  font-size:3.7vw;
  }

  .titlep_first{
    margin-top:78.5rem;
      margin-left: 21rem;
      width: 27rem;
  }
  
  .ref{
    margin-left: 11.5rem;
    margin-top: -2rem;
  
    }

    .titf{ 
      margin-top: -13rem;
      margin-left: 14rem;
      }
          
  .titlep_second{
    margin-top: -40.5rem;
    margin-left: 14rem;
    }
    
     
    .envio{
      margin-left: -4em;
      margin-top: -13rem;
      }

  .title-ind{
  margin-top: -38vh;
  margin-left: -8em;
  }
    
  .title-exp{
    height: 20em;
    width: 90%;
    left: 1.2rem;
    }  

      .camera{
      height: 15vh;
      margin-top: 38vh;
      width: 100%;
      margin-left: -8.2em;
      }
      

.titE{ 
width: 80vw;
  margin-top: 35rem;
  margin-left: 7rem;
  
}

.titlep_therd{
  width: 25rem;
  margin-top: 74rem;
  margin-left: 7rem;
  line-height:23px;

  }
  
  .slider_envio_first{
    margin-top: 1rem;
  }
  /* -------------------------------------------------------
   */

   
 .color2{
  width: 100vw;
  height: 62vh;
  }

  .how_p{
    margin-right: -0.5rem;
  }
  
 .title-privacity{
  margin-right: 29vw;
  font-size: 8.1vw;
  width: 90vw;
  line-height: 45px;
  margin-left: 10rem;
  }
  .subtitle-terms{
    margin-left: 1rem;
    width: 90vw;
    font-size:3.7vw;
    line-height: 21px;
    }

    .candado{
      width:70vw;
      margin-top: -3.5rem;
      margin-left:-7rem ;
    }

    .title-terms1,.title-terms2{
      font-size: 5.5vw;
      margin-left: -1.5em;
      }
      
      .title-terms1{
      width: 60vw;
      margin-left: 1.5rem;
      }
    
      .subtitle-terms1{
        height: 105vh;
        font-size:3.1vw;
        line-height: 21px;
        text-align: left;
        width: 60vw;
        margin-left: 5.5rem;
        margin-top:2rem;
        }

  
        .subtitle-terms2{
          width: 60vw;
          font-size:3.1vw;
          line-height: 21px;
          text-align: left;
          margin-left: 8rem;
    
          }
          
.terms2{
margin-top: 10rem;
  height: 80vh;
}

.section_privacity{ height: 135vh;}

.title-terms{
  width: 60vw;
  margin-left: 1.5rem;
  font-size: 8.1vw;
  line-height: 54px;
  }

.subtitle-conditions{
  margin-left: 11rem;
  margin-top: -.5rem;
width: 90vw;
font-size:3.7vw;
line-height: 21px;
}

  .carretilla{
  margin-left: -2rem;
  width: 54vw;
  margin-top: -1rem;
  }

  .title-terms2{ 
    margin-left: 1.5rem;
    width: 62vw;}

.subtitle-conditions_2{ 
  margin-top: -15.5rem;
}

.color4{
  height: 120vh;
}

  .section-title-wrap-one{
  height:18em ;
  width: 24.5em;
  }
  
  .exi-two{  height: 70vh;}
  
  .exi-two{margin-top: 25em;}
  
  .carousel-slider{
  width: 75vw;
  margin-left: 9vw;
  }
  
  .title-wrap{
  margin-top: -10vh;
  height: 40vh;
  width: 100vw;
  }
  
  .privacity_two{
  margin-top:-22rem;
  height: 55vh;
  }
  
  .privacity_three{
  margin-top:-10rem;
  height: 55vh;
  }
  
  .title_conditions{
  height: 25vh;
  margin-top: -3.5rem;
  }

    
  }
  